import * as AK from '@ariakit/react'
import {Icon} from 'quickstart/components/content/Icon'
import {tid} from 'quickstart/utils'
import * as React from 'react'
import AnimateHeight from 'react-animate-height'
import {logger} from 'tizra'
import * as S from './styles'
import {ReactElement} from 'react'

const log = logger('Accordion')

type AccordionProps = Omit<
  React.ComponentProps<typeof S.Accordion>,
  'children'
> & {
  children: React.ReactNode | ((props: {visible: boolean}) => React.ReactNode)
  'data-testid'?: string
  title: React.ReactNode
  defaultOpen?: boolean
}

const _Accordion = ({
  children,
  'data-testid': dataTestId,
  defaultOpen,
  title,
  ...rest
}: AccordionProps) => {
  const store = AK.useDisclosureStore({defaultOpen, animated: true})
  const open = store.useState('open')
  const visible = store.useState(({animating, open}) => animating || open)
  return (
    <S.Accordion {...rest} {...tid(dataTestId)}>
      <S.Disclosure store={store}>
        {title}
        <S.Icon open={open}>
          <Icon icon="chevronDown" />
        </S.Icon>
      </S.Disclosure>
      <AnimateHeight
        height={open ? 'auto' : 0}
        onAnimationEnd={store.stopAnimation}
      >
        <S.DisclosureContent open={open} store={store}>
          {typeof children === 'function' ? children({visible}) : children}
        </S.DisclosureContent>
      </AnimateHeight>
    </S.Accordion>
  )
}

type GroupProps = Omit<React.ComponentProps<typeof S.Group>, 'children'> & {
  children: ReactElement<AccordionProps> | Array<ReactElement<AccordionProps>>
  defaultOpen?: number[] | true | false
}

const Group = React.forwardRef<any, GroupProps>(
  ({children, defaultOpen = [0], ...props}, ref) => {
    return (
      <S.Group {...props} ref={ref}>
        {React.Children.map(children, (child, i) => {
          const open =
            Array.isArray(defaultOpen) ? defaultOpen.includes(i) : defaultOpen
          return React.cloneElement(child, {
            key: `${child.key}:${open}`, // make sure it rerenders
            defaultOpen: open,
          })
        })}
      </S.Group>
    )
  },
)

export const Accordion = Object.assign(_Accordion, {Group})
