import * as AK from '@ariakit/react'
import {useEffectCallback, useHydrating} from 'quickstart/hooks'
import {transient} from 'quickstart/styled-components/system'
import {UnprefixTransientProps} from 'quickstart/types'
import * as R from 'rambdax'
import {ComponentProps, useCallback, useEffect} from 'react'
import {IS_STORYBOOK} from 'tizra'
import * as S from './styles'

// exported as Modal.useStore
const useModalStore = (options?: AK.DialogStoreProps) =>
  AK.useDialogStore({animated: true, ...options})

type ModalProps = ComponentProps<typeof AK.Dialog> &
  UnprefixTransientProps<ComponentProps<typeof S.Dialog>> & {
    onClose?: () => void | undefined
  }

const _Modal = ({
  children,
  onClose,
  size = 'lg',
  store,
  ...props
}: ModalProps) => {
  const mounted = store.useState('mounted')

  onClose = useEffectCallback(onClose)
  useEffect(() => {
    if (mounted) return onClose
  }, [mounted, onClose])

  const getPortalElement = useCallback(() => {
    let portalRoot = document.getElementById('eg-portal-root')
    if (!portalRoot) {
      portalRoot = document.createElement('div')
      portalRoot.id = 'eg-portal-root'
      document.body.appendChild(portalRoot)
    }
    const portalElement = document.createElement('div')
    portalRoot.appendChild(portalElement)
    return portalElement
  }, [])

  const rendered = (
    <AK.Dialog
      store={store}
      backdrop={false}
      portalElement={IS_STORYBOOK ? getPortalElement : undefined}
      render={props => (
        <S.Backdrop
          style={{display: mounted ? undefined : 'none'}}
          {...R.pick(['data-enter'], props)}
        >
          <S.Dialog {...transient({size})} {...props} />
        </S.Backdrop>
      )}
      {...props}
    >
      <S.Close onClick={store.hide} />
      {children}
    </AK.Dialog>
  )

  // https://github.com/facebook/react/issues/12615
  return useHydrating() ? null : rendered
}

export const Modal = Object.assign(_Modal, {
  Content: S.Content,
  Title: S.Title,
  Footer: S.Footer,
  Cover: S.Cover,
  useStore: useModalStore,
})
