import * as B from '../block'
import {Admin, GlobalAdmin} from './admin'
import {defaultGlobalConfig, globalMigrate} from './globalConfig'
import {LinariaGlobalStyle, StyledComponentsGlobalStyle} from './globalStyles'

export const ThemeBlock = B.blockWithInfo(
  {
    name: 'theme',
    displayName: 'ThemeBlock',
    title: 'Theme', // required but not used
    Admin,
    GlobalAdmin,
    globalMigrate,
    defaultGlobalConfig,
    wrapperDiv: false,
  },
  () => (
    <>
      <LinariaGlobalStyle />
      <StyledComponentsGlobalStyle />
    </>
  ),
)

export const useThemeConfig = () => B.useGlobalConfig(ThemeBlock)
