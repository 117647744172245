import {Results} from 'quickstart/components/content/Results'
import {Prose, Text} from 'quickstart/components/content/Text'
import {DropdownSelect} from 'quickstart/components/controls/DropdownSelect'
import {MetaSummary} from 'quickstart/components/tizra/MetaSummary'
import {SearchForm} from 'quickstart/components/tizra/SearchForm'
import {UseSearchReturn, useLocation, useVisible} from 'quickstart/hooks'
import * as R from 'rambdax'
import {ComponentProps, useEffect} from 'react'
import * as Final from 'react-final-form'
import {logger} from 'tizra'

const log = logger('SearchResults')

interface SearchResultsProps
  extends Omit<ComponentProps<typeof Results>, 'count' | 'items'> {
  search: UseSearchReturn
  showPageThumbnails?: boolean
}

export const SearchResults = ({
  search,
  search: {
    fetchNextPage,
    hasNextPage,
    isFetching,
    lastResults,
    params,
    results,
    size,
  },
  showPageThumbnails = true,
  ...rest
}: SearchResultsProps) => {
  const [bottomIsVisible, lastItemRef] = useVisible({bots: false})
  useEffect(() => {
    if (
      bottomIsVisible &&
      results &&
      hasNextPage &&
      fetchNextPage &&
      !isFetching
    ) {
      fetchNextPage()
    }
  }, [bottomIsVisible, hasNextPage, fetchNextPage, isFetching, results])

  const {pathname, search: qs, hash} = useLocation()
  const backToSearch = `${pathname}${qs}${hash}`

  const highlightText = params?.terms

  const items = lastResults
    ?.map(
      (r, i) =>
        r && (
          <MetaSummary
            key={i}
            metaObj={r}
            ref={i === lastResults.length - 1 ? lastItemRef : undefined}
            linkOptions={{
              readerQuery: R.filter(Boolean, {backToSearch, highlightText}),
            }}
            thumbnail={
              // This doesn't handle PageRange yet because it would need to
              // check for a configured cover image versus a page thumbnail
              // fallback. We don't have to worry about those for PdfPage or
              // toc-entry, since they can't have configured cover images.
              r.metaType === 'PdfPage' || r.metaType === 'toc-entry' ?
                showPageThumbnails
              : true
            }
            titlingPropPrefix="SearchResult"
          />
        ),
    )
    .filter(Boolean)

  return (
    <Results
      count={size}
      items={items}
      control={size ? <SortControl search={search} /> : undefined}
      noResultsMessage={
        <Prose>
          <Text variant="h3" as="p">
            We're sorry, we weren't able to find any results for ‘
            {search.params.terms}’
          </Text>
          {search.params.terms ?
            <Text>
              Please double-check your spelling, or try a different search term.
            </Text>
          : <Text>
              Try adding some terms to search for, or choosing from the filters
              list.
            </Text>
          }
        </Prose>
      }
      {...rest}
    />
  )
}

interface SortControlProps {
  search: UseSearchReturn
}

function SortControl({search}: SortControlProps) {
  const field = search.config?.fields?.sort
  const _options = field?.options
  if (
    !_options ||
    !Array.isArray(_options) ||
    _options.length < 2 ||
    typeof _options[0] !== 'object'
  ) {
    return null
  }
  const options = (
    _options as Array<{value: string; text?: string; label?: string}>
  ).map(({value, text = value, label = text}) => ({value, label}))
  return (
    <SearchForm search={search} instant={true}>
      <Final.Field name="sort">
        {({input}) => {
          const {label} = options.find(o => o.value === input.value) || {}
          return (
            <DropdownSelect
              aria-label="Sort menu"
              options={options}
              placement={'bottom-end'}
              {...input}
            >
              Sort by {label}
            </DropdownSelect>
          )
        }}
      </Final.Field>
    </SearchForm>
  )
}
