import {FORM_ERROR} from 'final-form'
import createDecorator, {FocusableInput} from 'final-form-focus'
import * as R from 'rambdax'
import {isEmptyObj} from 'tizra'

interface Error {
  reason?: string
  message?: string
}

interface ErrorMap {
  [field: string]: Error
}

interface Messages {
  [field: string]: {
    [reason: string]: string | JSX.Element
  }
}

function fieldError(
  {reason, message}: Error,
  field: string,
  messages: Messages = {},
): string | JSX.Element {
  const reasonedMessage =
    reason && (messages[field]?.[reason] || messages._?.[reason])
  return reasonedMessage || message || reason || 'Unknown error'
}

export function formErrors({
  errors,
  messages,
  reason,
  message,
  status,
}: {
  errors?: ErrorMap
  messages?: Messages
  reason?: string
  message?: string
  status?: number
}) {
  // For some instances of required, the server message is "Must be a non-empty
  // string." which isn't helpful for actual users, only human API callers.
  messages = {
    // API uses _ to indicate FORM_ERROR, but we use _ in messages to indicate
    // fallback errors for fields. Got it?
    _: {
      required: 'This field is required.',
      ...messages?._,
    },
    ...messages,
  }

  // Don't be mute.
  if (!errors || isEmptyObj(errors)) {
    reason ||= status ? `${status}` : 'unknown'
    message ||= `Unknown error (${reason})`
  }

  // Return errors object. Final.Form will pass field errors to their associated
  // Final.Field. FORM_ERROR goes to submitError in the Final.Form
  // FormRenderProps.
  return R.map<Error, string | JSX.Element>(
    (v, k) => fieldError(v, k, messages),
    errors && !R.isEmpty(errors) ?
      R.renameProps<ErrorMap>({_: FORM_ERROR}, errors)
    : {[FORM_ERROR]: {reason, message}},
  )
}

// The default getInputs defined by final-form-focus looks at all forms on the
// page. This can easily focus on the wrong one, especially if there are
// multiple hidden forms. We look at the active element and work upward to find
// the form, which isn't perfect but is more likely to work as expected.
const getInputs = () => {
  if (typeof document === 'undefined') {
    return []
  }
  return Array.from(
    document.activeElement?.closest('form')?.elements ?? [],
  ).filter(
    el => typeof (el as any)?.focus === 'function',
  ) as unknown as FocusableInput[]
}

export const focusError = createDecorator<any>(getInputs)
