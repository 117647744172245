import {ComponentProps, ReactNode, forwardRef} from 'react'
import * as S from './styles'
import {UseLazyImgProps, useLazyImgProps} from './useLazyImgProps'

type ImageProps = Omit<ComponentProps<typeof S.Image>, 'src'> &
  Omit<UseLazyImgProps, 'src'> & {
    fallback?: ReactNode
    src?: string
  }

const KeyedImage = forwardRef<HTMLImageElement, ImageProps>(
  ({fallback = null, ...props}, ref) => {
    const imgProps = useLazyImgProps({...props, ref})
    return imgProps ? <S.Image {...imgProps} /> : fallback
  },
)

const _Image = forwardRef<HTMLImageElement, ImageProps>(
  ({src, ...props}, ref) => (
    <KeyedImage key={src} src={src} ref={ref} {...props} />
  ),
)

export const Image = Object.assign(_Image, {
  Caption: S.Caption,
})

export const StyledImage = S.Image // for nested styles
