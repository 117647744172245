import * as Final from 'react-final-form'

export const SearchForm = ({
  children,
  instant = false,
  search: {onChange, onSubmit, params},
  ...rest
}) => (
  <Final.Form
    initialValues={params}
    onSubmit={onSubmit}
    render={renderProps => (
      <form onSubmit={renderProps.handleSubmit}>
        {instant ?
          <Final.FormSpy onChange={onChange} />
        : null}
        {typeof children === 'function' ? children(renderProps) : children}
      </form>
    )}
    {...rest}
  />
)

/*
SearchForm.propTypes = {
  instant: T.bool,
  search: T.object.isRequired,
}
*/
