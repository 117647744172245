import {QueryClientProvider} from '@tanstack/react-query'
import arrayMutators from 'final-form-arrays'
import {ThemeProvider} from 'quickstart/blocks/ThemeBlock/branding'
import {
  LinariaGlobalStyle,
  StyledComponentsGlobalStyle,
} from 'quickstart/blocks/ThemeBlock/globalStyles'
import {
  AdminInnerWrapperProps,
  AdminWrapperProps,
  GlobalMigrateWrapper,
  MigrateWrapper,
} from 'quickstart/types'
import {tid} from 'quickstart/utils'
import {useCallback, useState} from 'react'
import * as Final from 'react-final-form'
import {logger, stableJson, tryJson} from 'tizra'
import {AdminContextProvider, fromServer} from '../base/hooks/useAdminContext'
import * as S from './styles'
import {HydrationProvider} from 'quickstart/hooks'

const log = logger('admin/renderer')

export const AdminInnerWrapper = ({
  children,
  global,
  context,
  queryClient,
}: AdminInnerWrapperProps) => (
  <HydrationProvider hydrate={false}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider context={context}>
        <LinariaGlobalStyle />
        <StyledComponentsGlobalStyle />
        <AdminContextProvider context={context} global={global}>
          <S.AdminWrapper {...tid('admin')}>{children}</S.AdminWrapper>
        </AdminContextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </HydrationProvider>
)

export function AdminWrapper<C>({
  component: Admin,
  global = false, // set by blockWithInfo() in block.js
  context,
  migrate,
  name,
  queryClient,
  textarea,
}: AdminWrapperProps<C>) {
  const [config] = useState(() => {
    if (textarea.value.trim().length !== 0) {
      const tv = tryJson<any>(textarea.value, e => log.error(e))
      return global ? tv?.[name] : tv
    }
  })

  const [initialValues] = useState<C>(() =>
    global ?
      (migrate as GlobalMigrateWrapper<C>)({
        adminContext: fromServer(context, global),
        globalConfig: config,
      })
    : (migrate as MigrateWrapper<C>)({
        adminContext: fromServer(context, global),
        config,
      }),
  )

  const handleChange = useCallback(
    ({values}: {values: C}) => {
      const tv =
        global ?
          {
            ...tryJson<any>(textarea.value),
            [name]: values,
          }
        : values
      textarea.value = stableJson(tv, {indent: 2})
    },
    [global, name, textarea],
  )

  return (
    <Final.Form<C>
      onSubmit={() => {
        log.warn('FinalForm submitted, whoops?')
      }}
      initialValues={initialValues}
      mutators={{...arrayMutators}}
      render={({handleSubmit, ...renderProps}) => (
        <form onSubmit={handleSubmit}>
          <Final.FormSpy onChange={handleChange} />
          <AdminInnerWrapper
            context={context}
            global={global}
            queryClient={queryClient}
          >
            <Admin {...renderProps} />
          </AdminInnerWrapper>
        </form>
      )}
    />
  )
}
