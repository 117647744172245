import {useTheme} from 'quickstart/styled-components/system'
import {truish} from 'quickstart/types'
import * as R from 'rambdax'
import {
  Children,
  cloneElement,
  ComponentProps,
  forwardRef,
  ReactElement,
  ReactNode,
} from 'react'
import {Box} from '../../layout'

const systemMap = R.curry((fn, x) => {
  if (typeof x === 'object') {
    return R.map(fn, x)
  }
  return fn(x)
})

const getCol = R.curry((i, cols) => {
  const getColValue = (cols: any): any =>
    Array.isArray(cols) ? cols[i % cols.length]
    : typeof cols === 'number' && cols >= 1 ? 1 / cols
    : typeof cols === 'object' ? R.map(getColValue, {xs: 1, ...cols})
    : cols
  const col = getColValue(cols)
  if (col !== undefined) {
    return {col}
  }
})

type FlexGridProps = ComponentProps<typeof Box> & {
  colGutter?: any
  cols?: any
  gutter?: any
  reversed?: boolean
  rowGutter?: any
}

const _FlexGrid = forwardRef<any, FlexGridProps>(
  (
    {children, colGutter, cols, gutter, reversed, rowGutter, ...props},
    forwardedRef,
  ) => {
    const {flexGrids, space} = useTheme()!

    // Gutters come from props first, then fall back to theme.
    colGutter = colGutter ?? gutter ?? flexGrids.colGutter
    rowGutter = rowGutter ?? gutter ?? flexGrids.rowGutter

    // Negative margin for rows, positive padding for columns (cells).
    const negativeMargin = systemMap(
      (x: any) => `calc(-.5 * (${space[x] ?? x}))`,
    )
    const positivePadding = systemMap(
      (x: any) => `calc(.5 * (${space[x] ?? x}))`,
    )

    const rowProps = {
      mx: negativeMargin(colGutter),
      my: negativeMargin(rowGutter),
    }

    const colProps = (i: number, childProps: any = {}) => ({
      px: positivePadding(colGutter),
      py: positivePadding(rowGutter),
      order: childProps.order ?? (reversed ? -1 - i : 0),
      ...(R.isNil(childProps.col) && getCol(i, cols)),
    })

    return (
      <Box {...props} ref={forwardedRef}>
        <Box row {...rowProps}>
          {Children.map(children, (child, i) =>
            // Wrap any bare children in FlexGrid.Col
            isFlexGridCol(child) ?
              cloneElement(child, colProps(i, child.props))
            : <FlexGrid.Col {...colProps(i)}>{child}</FlexGrid.Col>,
          )}
        </Box>
      </Box>
    )
  },
)

const Col = forwardRef<any, ComponentProps<typeof Box>>(
  (props, forwardedRef) => (
    // Don't put col: true in defaultProps because that trips FlexGrid's colProps
    // detection above.
    <Box
      col
      ref={forwardedRef}
      // The following props allow a Card in a FlexGrid to fully occupy the vertical
      // space in the row, determined by the tallest card.
      display="flex"
      flexDirection="column"
      justifyContent="stretch"
      {...props}
    />
  ),
)

export const FlexGrid = Object.assign(_FlexGrid, {Col})

function isFlexGridCol(
  child: unknown,
): child is ReactElement<ComponentProps<typeof FlexGrid.Col>> {
  return (
    !!child &&
    typeof child === 'object' &&
    'type' in child &&
    child.type === FlexGrid.Col
  )
}

export const LeftRight = ({
  children: [left, right],
  ...props
}: {
  children: [ReactNode, ReactNode]
}) => (
  <FlexGrid {...props}>
    <FlexGrid.Col>{left}</FlexGrid.Col>
    {truish(right) && <FlexGrid.Col col="auto">{right}</FlexGrid.Col>}
  </FlexGrid>
)
