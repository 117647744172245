import * as AK from '@ariakit/react'
import {Button as ButtonComponent} from 'quickstart/components/controls/Button'
import styled, {
  breakpoints,
  css,
  shouldForwardProp,
  system,
  SystemProps,
  th,
} from 'quickstart/styled-components/system'
import {ComponentProps, forwardRef} from 'react'

export type VariantProps = {variant?: 'nav' | 'simple'}

export const Button = forwardRef<
  any,
  VariantProps &
    Omit<ComponentProps<typeof ButtonComponent>, 'variant'> &
    Omit<ComponentProps<typeof AK.MenuButton>, 'as'>
>(({variant = 'nav', ...props}, ref) => (
  <ButtonComponent
    as={AK.MenuButton}
    variant={
      variant === 'nav' ? 'nav'
      : variant === 'simple' ?
        'stealth'
      : (undefined as never)
    }
    display="flex"
    {...props}
    style={{textAlign: 'left', ...props.style}}
    ref={ref}
  />
))

// We have to strip 'as' from props before wrapping with styled, because of
// sc6 bug that can't handle clash between 'as' definitions.
const _MenuItem = forwardRef<
  any,
  Omit<ComponentProps<typeof AK.MenuItem>, 'as'>
>((props, ref) => <AK.MenuItem {...props} ref={ref} />)

export const Item = styled(_MenuItem).withConfig({
  shouldForwardProp,
})<VariantProps & SystemProps>`
  display: flex;
  align-items: center;
  width: 100%;
  border: 0;
  appearance: none;
  cursor: pointer;
  transition: ${th('transitions.medium')};
  text-decoration: none;
  padding: ${th('space.lg')} ${th('space.xl')};

  ${th(`dropdownMenus.default.item`)};
  ${system};

  &[type='button'] {
    appearance: none;
  }

  &:hover,
  &:focus {
    outline: none !important; /* important for firefox */
  }
`

export const Separator = styled(AK.MenuSeparator)<VariantProps & SystemProps>`
  ${th(`dropdownMenus.default.separator`)};
  ${system};
`

export const Menu = styled(AK.Menu).withConfig({shouldForwardProp})<
  VariantProps & SystemProps
>(
  ({variant}) => css`
    max-height: var(--popover-available-height);
    overflow: visible;
    z-index: 1;
    transition: opacity 200ms;

    width: 12rem;
    ${variant === 'nav' &&
    breakpoints({
      md: css`
        width: 14rem;
      `,
      lg: css`
        width: 15rem;
      `,
      xl: css`
        width: 16rem;
      `,
    })};

    ${th(`dropdownMenus.default.menu`)};
    ${system};

    &:focus {
      outline: none !important; /* important for firefox */
    }

    &[hidden] {
      display: none;
    }
  `,
)
