import {Text} from 'quickstart/components/content/Text'
import {Stack} from 'quickstart/components/layout/Stack'
import {MetaCard} from 'quickstart/components/tizra/MetaCard'
import {MetaDigest} from 'quickstart/components/tizra/MetaDigest'
import {MetaHeadline} from 'quickstart/components/tizra/MetaHeadline'
import {MetaLink} from 'quickstart/components/tizra/MetaLink'
import {MetaSummary} from 'quickstart/components/tizra/MetaSummary'
import {MetaTile, TileSize} from 'quickstart/components/tizra/MetaTile'
import {
  ImageCropping,
  ImageFocus,
  SlotConfig,
  SlotName,
} from 'quickstart/components/tizra/MetaTile/common'
import {UseSearchReturn, useGeneration, useVisible} from 'quickstart/hooks'
import {
  ComponentProps,
  ReactNode,
  forwardRef,
  useEffect,
  useId,
  useMemo,
} from 'react'
import {Flipped, Flipper} from 'react-flip-toolkit'
import {
  GenericMetaObject,
  MetaObject,
  SearchResult,
  isTocEntry,
  logger,
  semiSplit,
} from 'tizra'
import {FitStack} from './FitStack'
import * as S from './styles'

const log = logger('BrowseGallery')

type Display = 'cards' | 'digests' | 'list' | 'headline' | 'titles' | 'tiles'

type BrowseGalleryProps = ComponentProps<'div'> & {
  display: Display
  search: UseSearchReturn<MetaObject>
  as?: any
  maxItems?: number
  size?: TileSize
  slots?: {[k in SlotName]?: SlotConfig}
  background?: string
  foreground?: string
  linkColor?: string
  twoColumnHeadline?: boolean
  imageCropping?: ImageCropping
  imageFocus?: ImageFocus
}

const BrowseLayout = ({
  display,
  size,
  background,
  foreground,
  linkColor,
  twoColumnHeadline,
  realignImages,
  ...props
}: {
  children: ReactNode
  display: Display
  size?: TileSize
  background?: string
  foreground?: string
  linkColor?: string
  twoColumnHeadline?: boolean
  realignImages?: boolean
}) => {
  switch (display) {
    case 'cards':
      return <S.BrowseGalleryGrid {...props} />
    case 'digests':
      return (
        <MetaDigest.Group
          background={background}
          foreground={foreground}
          linkColor={linkColor}
          {...props}
        />
      )
    case 'headline':
      return <div {...props} />
    case 'list':
      return <Stack spacing="md" divided {...props} />
    case 'titles':
      return twoColumnHeadline ?
          <FitStack divided spacing="xl" wrapChildren={false} {...props} />
        : <Stack
            divided
            startCapped
            spacing="xl"
            wrapChildren={false}
            {...props}
          />
    case 'tiles':
      return (
        <MetaTile.Group
          display={display}
          size={size}
          realignImages={realignImages}
          {...props}
        />
      )
    default: {
      const exhaustiveCheck: never = display
      log.error(`getLayoutForDisplay unhandled case: ${exhaustiveCheck}`)
      return <S.BrowseGalleryGrid {...props} />
    }
  }
}

type BrowseItemProps = {
  display: Display
  metaObj: GenericMetaObject
  size?: TileSize
  slots?: {[k in SlotName]?: SlotConfig}
  imageCropping?: ImageCropping
  imageFocus?: ImageFocus
}

const BrowseItem = forwardRef<any, BrowseItemProps>(
  ({display, imageCropping, imageFocus, size, slots, ...props}, ref) => {
    switch (display) {
      case 'list':
        return <MetaSummary {...props} ref={ref} />
      case 'cards':
        return <MetaCard stretch slots={slots} {...props} ref={ref} />
      case 'headline':
        return <MetaHeadline slots={slots} {...props} ref={ref} />
      case 'titles':
        return (
          <Text variant="textMd">
            <MetaLink
              variant="stealth"
              prop={[...semiSplit(slots?.title?.prop), '_name']}
              {...props}
              ref={ref}
            />
          </Text>
        )
      case 'tiles':
        return (
          <MetaTile
            imageCropping={imageCropping}
            imageFocus={imageFocus}
            size={size}
            slots={slots}
            {...props}
            ref={ref}
          />
        )
      case 'digests':
        return <MetaDigest slots={slots} {...props} />
      default: {
        const exhaustiveCheck: never = display
        log.error(`getComponentForDisplay unhandled case: ${exhaustiveCheck}`)
        return <MetaCard stretch {...props} ref={ref} />
      }
    }
  },
)

const getId = (r: SearchResult) => (isTocEntry(r) ? r.id : r.tizraId)

export const BrowseGallery = ({
  as: ElementType = 'div',
  display,
  maxItems = 0,
  search: {hasNextPage, fetchNextPage, isFetching, lastResults, results},
  size,
  slots,
  background,
  foreground,
  linkColor,
  imageCropping,
  imageFocus,
  twoColumnHeadline,
  ...rest
}: BrowseGalleryProps) => {
  const [bottomIsVisible, lastItemRef] = useVisible({bots: false})

  useEffect(() => {
    if (
      bottomIsVisible &&
      results &&
      (!maxItems || results.length < maxItems) &&
      hasNextPage &&
      !isFetching
    ) {
      fetchNextPage!()
    }
  }, [
    bottomIsVisible,
    hasNextPage,
    fetchNextPage,
    isFetching,
    maxItems,
    results,
  ])

  const items = useMemo(
    () => (maxItems ? lastResults?.slice(0, maxItems) : lastResults),
    [lastResults, maxItems],
  )

  const flipKey = useGeneration([items?.[0]])

  const portalKey = useId()

  return (
    <ElementType {...rest}>
      <Flipper flipKey={flipKey} portalKey={portalKey}>
        <BrowseLayout
          display={display}
          size={size}
          background={background}
          foreground={foreground}
          linkColor={linkColor}
          twoColumnHeadline={twoColumnHeadline}
          realignImages={imageCropping === 'full'}
        >
          {items?.map((r, i) => (
            <Flipped key={getId(r)} flipId={getId(r)} translate>
              <BrowseItem
                display={display}
                metaObj={r}
                ref={i === items.length - 1 ? lastItemRef : undefined}
                size={size}
                imageCropping={imageCropping}
                imageFocus={imageFocus}
                slots={slots}
              />
            </Flipped>
          ))}
        </BrowseLayout>
      </Flipper>
      {
        // TODO loading spinner
        results && isFetching && (
          <Text style={{textAlign: 'center'}}>Loading...</Text>
        )
      }
    </ElementType>
  )
}
