import * as AK from '@ariakit/react'
import styled, {
  css,
  shouldForwardProp,
  system,
  SystemProps,
  th,
} from 'quickstart/styled-components/system'
import {ComponentProps, forwardRef} from 'react'

type ButtonProps = {
  disabled?: boolean
  $fluid?: boolean
  $hoverable?: boolean
  $size?: 'xs' | 'sm' | 'md' | 'lg'
  $variant?: 'primary' | 'secondary' | 'tertiary' | 'stealth' | 'nav'
} & SystemProps

// We have to strip 'as' from props before wrapping with styled, because of sc6
// bug that can't handle clash between 'as' definitions.
const _Button = forwardRef<any, Omit<ComponentProps<typeof AK.Button>, 'as'>>(
  (props, ref) => <AK.Button {...props} ref={ref} />,
)

export const Button = styled(_Button).withConfig({
  shouldForwardProp,
})<ButtonProps>(
  ({disabled, $fluid, $hoverable, $size = 'lg', $variant = 'primary'}) => {
    return css`
      ${th('buttons.base')};
      ${th(`buttons.${$variant}`)};
      ${disabled && th(`buttons.disabled`)};
      ${$variant !== 'stealth' &&
      $variant !== 'nav' &&
      th(`buttons.sizes.${$size}`)};

      ${$fluid &&
      css`
        width: 100%;
      `};

      // Space between icon and text
      & > *:not(:only-child):not(:last-child) {
        margin-right: ${th('space.xs')};
      }

      ${system};

      ${!disabled &&
      css`
        &:focus {
          ${th(`buttons.focus.${$variant}`)};
        }
      `};

      ${!disabled &&
      $hoverable &&
      css`
        &:hover {
          ${th(`buttons.hover.${$variant}`)};
        }
      `};

      &[disabled] {
        cursor: not-allowed;
      }
    `
  },
)
