import {UseWithinFieldConfig, defaultTips} from 'quickstart/hooks'
import {useField} from 'react-final-form'
import {useCallback, useRef, MouseEvent, MutableRefObject} from 'react'
import * as A from '../admin'

export const SearchAdmin = () => (
  <p>
    Search is configured globally in <A.NavOut tab="Design" subtab="features" />
    .
  </p>
)

export interface SearchBlockGlobalConfig extends A.GlobalBlockConfig {
  metadataMetaTypes: string[]
  tocDepth: boolean
  fulltextDepth: boolean
  showPageThumbnails: boolean
  fields: {
    terms: {
      customTips: string
    }
    within: UseWithinFieldConfig
  }
  customSearchConfigJson: string
}

export const defaultGlobalConfig: SearchBlockGlobalConfig = {
  metadataMetaTypes: ['Book', 'Video'],
  tocDepth: true,
  fulltextDepth: true,
  showPageThumbnails: true,
  fields: {
    terms: {customTips: ''},
    within: {mode: 'context', allOptionLabel: 'All content'},
  },
  customSearchConfigJson: '',
  FREEMARKER: {
    'fields.terms.customTips': 'markdown',
  },
}

const SearchTipsHint = ({
  name,
  inputRef,
}: {
  name: string
  inputRef: MutableRefObject<HTMLTextAreaElement | undefined>
}) => {
  const {
    input: {onChange},
  } = useField(name)
  const populateDefault = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      // Tell Final Form about the change...
      onChange(defaultTips)
      // ...and change immediately in the textarea, so that we can focus, select
      // all, and scroll to the top. If we only set via Final Form, then we
      // can't immediately select all. If we only set value directly, then Final
      // Form will override.
      const input = inputRef.current
      if (input) {
        input.value = defaultTips
        input.focus()
        input.select()
        input.scrollTop = 0
      }
    },
    [onChange, inputRef],
  )
  return (
    <span>
      Leave blank for default, or{' '}
      <a href="#" onClick={populateDefault}>
        click here to insert the default for customization.
      </a>
    </span>
  )
}

export const SearchGlobalAdmin: A.Admin<SearchBlockGlobalConfig> = () => {
  const metaTypeOptions = A.useMetaTypeOptions()
  const tipsRef = useRef<HTMLTextAreaElement>()
  return (
    <A.Group>
      <A.Checklist
        label="Meta-types for metadata search"
        name="metadataMetaTypes"
        options={metaTypeOptions}
      />
      <A.YesNo label="Search tables of contents?" name="tocDepth" />
      <A.YesNo label="Search full text?" name="fulltextDepth" />
      <A.YesNo label="Show page thumbnails?" name="showPageThumbnails" />
      {/* TODO: MarkdownEditor, need replacement for ref-based insertion */}
      <A.Textarea
        label="Custom search tips Markdown:"
        name="fields.terms.customTips"
        hint={
          <SearchTipsHint name="fields.terms.customTips" inputRef={tipsRef} />
        }
        ref={tipsRef}
      />
      <A.Textarea
        label="Custom search config JSON"
        name="customSearchConfigJson"
      />
    </A.Group>
  )
}
