import {useIsomorphicLayoutEffect, useMergedRefs} from 'quickstart/hooks'
import {transient} from 'quickstart/styled-components/system'
import {UnprefixTransientProps} from 'quickstart/types'
import {ComponentProps, forwardRef, useRef} from 'react'
import {logger} from 'tizra'
import * as S from './styles'

const log = logger('Tile')

type TileProps = UnprefixTransientProps<ComponentProps<typeof S.Tile>>

const _Tile = forwardRef<any, TileProps>(
  ({clickable, stretch, ...props}, ref) => (
    <S.Tile {...props} {...transient({clickable, stretch})} ref={ref} />
  ),
)

type GroupProps = UnprefixTransientProps<ComponentProps<typeof S.Group>> & {
  realignImages?: boolean
}

const RealigningGroup = forwardRef<any, GroupProps>(
  ({size, ...props}, forwardedRef) => {
    const ref = useRef<HTMLElement>()
    const mergedRef = useMergedRefs([forwardedRef, ref])

    useIsomorphicLayoutEffect(() => {
      if (ref.current) {
        const cleanups = [] as Array<() => void>
        const handler = () => adjustImagePaddings(ref.current)

        // Adjust image paddings when thumbnails load.
        ref.current.addEventListener('load', handler, true)

        // Adjust image paddings when the viewport changes, in case that changes
        // the number of tiles per row.
        window.addEventListener('resize', handler)
        cleanups.push(() => window.removeEventListener('resize', handler))

        // Adjust image paddings when the tiles move around, for filtered browse.
        const observer = new MutationObserver(handler)
        observer.observe(ref.current, {childList: true})
        cleanups.push(() => observer.disconnect())

        // Clean up on unmount.
        return () => cleanups.forEach(fn => fn())
      }
    }, [])

    return <S.Group {...props} {...transient({size})} ref={mergedRef} />
  },
)

const Group = forwardRef<any, GroupProps>(
  ({realignImages, size, ...props}, forwardedRef) =>
    realignImages ?
      <RealigningGroup {...props} size={size} ref={forwardedRef} />
    : <S.Group {...props} {...transient({size})} ref={forwardedRef} />,
)

function adjustImagePaddings(groupDiv: HTMLElement | null | undefined) {
  if (!groupDiv) return
  const tiles = [...groupDiv.children] as HTMLElement[]
  const rows = tiles.reduce(
    (rows, tile) => {
      const imgWrapper = tile.firstElementChild
      if (imgWrapper && imgWrapper.querySelector('img')) {
        const {top} = tile.getBoundingClientRect()
        const {height} = imgWrapper.getBoundingClientRect()
        ;(rows[top] ||= []).push({tile, height})
      }
      return rows
    },
    {} as {[k: string]: Array<{tile: HTMLElement; height: number}>},
  )
  for (const row of Object.values(rows)) {
    const maxHeight = Math.max(...row.map(r => r.height))
    for (const {tile, height} of row) {
      tile.style.paddingTop = `${maxHeight - height}px`
    }
  }
}

export const Tile = Object.assign(_Tile, {
  Cover: S.Cover,
  Body: S.Body,
  Footer: S.Footer,
  Label: S.Label,
  Heading: S.Heading,
  Meta: S.Meta,
  Description: S.Description,
  CallToAction: S.CallToAction,
  Group,
})
