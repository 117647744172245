import Downshift from 'downshift'
import {Icon} from 'quickstart/components/content/Icon'
import {ClearButton} from 'quickstart/components/controls/ClearButton'
import {useDebouncedCallback, useMergedRefs} from 'quickstart/hooks'
import {createEvent} from 'quickstart/utils'
import * as R from 'rambdax'
import {
  forwardRef,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react'
import {logger} from 'tizra'
import * as S from './styles'

// TODO this is inside-out. We should do it like the Axios example, see
// https://github.com/kentcdodds/downshift-examples/blob/master/src/other-examples/axios/index.js

// TODO handle nativeEvent.isComposing, see SuiSearch component

const log = logger('Search')

const EMPTY_RESULTS = []

export const Search = forwardRef(
  (
    {
      autoFocus,
      'data-testid': dataTestId,
      disabled,
      hint,
      icon,
      id: inputId,
      itemToString,

      // This value of minChars is separate from the value in the Tizra search
      // config. This value controls whether the search callback is called, but
      // the autocompleter might choose to avoid actually calling the API until
      // there are additional chars, referring to autoComplete.minChars in
      // default-config.js
      minChars = 1,

      name,

      // If component is controlled (value is not undefined), then this will be
      // called on every change to the input, otherwise it will be called only
      // when something is selected or the input is cleared.
      onChange,
      placeholder = 'Search…',
      renderItem,
      search,
      size = 'lg',

      // Advanced search shouldn't submit when something is chosen from the
      // dropdown, however quick search and search results should.
      // TODO submitOnSelect = false,

      // If value is defined, then this component becomes controlled. Controlled
      // is the normal state when used in React Final Form.
      value,

      variant,

      // Similar to minChars above, this controls how the Search component
      // debounces calls to the search callback. If this is null or undefined, the
      // callback will not be debounced, though the callback (namely the
      // autocompleter) might implement its own internal debouncing.
      wait,

      ...rest
    },
    forwadedRef,
  ) => {
    // Keep results in state
    const [results, setResults] = useState(EMPTY_RESULTS)

    // Autofocus
    const inputRef = useRef()
    useEffect(() => {
      if (autoFocus) {
        inputRef.current?.focus()
      }
    }, [autoFocus, inputRef])

    // Merge refs for passing through
    const ref = useMergedRefs([forwadedRef, inputRef])

    // When typing, call onChange if controlled, and update results. This is
    // two-tiered so that updating results can be debounced, but calling parent
    // onChange should not be debounced.
    const controlled = value !== undefined
    const updateResults = useDebouncedCallback(
      async value => {
        if (!search || !value || value.length < minChars) {
          setResults(EMPTY_RESULTS)
        } else {
          const data = await search(
            value,
            inputRef.current?.selectionStart ?? value.length,
            inputRef.current?.selectionEnd ?? value.length,
          )
          // This check avoids breakage under test, where everything is
          // unmounted and the global window goes away, but this async function
          // hasn't finished running.
          if (typeof window !== 'undefined') {
            setResults(data || EMPTY_RESULTS)
          }
        }
      },
      {wait},
      [inputRef, minChars, search, wait],
    )
    const updateControlledValue = useCallback(
      value => {
        if (controlled) {
          onChange?.(createEvent({name, value}))
        }
      },
      [controlled, name, onChange],
    )
    const handleInputChange = useCallback(
      value => {
        updateResults(value)
        updateControlledValue(value)
      },
      [updateControlledValue, updateResults],
    )

    // Send event to parent on select or clear.
    const handleSelect = useCallback(
      item => {
        updateResults.cancel?.()
        setResults(EMPTY_RESULTS)
        updateControlledValue(item ? itemToString(item) : '')
      },
      [itemToString, updateControlledValue, updateResults],
    )

    const handleOuterClick = useCallback(() => {
      updateResults.cancel?.()
      setResults(EMPTY_RESULTS)
    }, [updateResults])

    const stateReducer = useCallback(
      (state, changes) => {
        let mods

        switch (changes.type) {
          case Downshift.stateChangeTypes.blurInput:
          case Downshift.stateChangeTypes.mouseUp:
            // Preserve the current input value instead of resetting it.
            mods = {
              inputValue: state.inputValue,
            }
            break

          case Downshift.stateChangeTypes.keyDownEscape:
            // Setting inputValue here unfortunately doesn't seem to stick.
            mods = {
              inputValue: state.inputValue,
            }
            break

          case Downshift.stateChangeTypes.keyDownArrowDown:
          case Downshift.stateChangeTypes.keyDownArrowUp:
            if (
              changes.type === Downshift.stateChangeTypes.keyDownArrowUp &&
              state.highlightedIndex === 0
            ) {
              // At the top of the list, if the user hits up arrow again, restore
              // the original input and stop highlighting.
              mods = {
                highlightedIndex: null,
                ...(!R.isNil(state.savedInputValue) && {
                  inputValue: state.savedInputValue,
                  savedInputValue: null,
                }),
              }
            } else {
              // When the results box is closed, then we get a pair of arrow events:
              // first isOpen true, then set highlightedIndex. We want to detect the
              // pair to avoid highlighting when the box opens.
              mods = {
                ...(state.justOpened ?
                  {highlightedIndex: null}
                : typeof changes.highlightedIndex === 'number' && {
                    inputValue: itemToString(results[changes.highlightedIndex]),
                  }),
                justOpened: !state.isOpen && !!changes.isOpen,
              }
              // Additionally, if nothing was highlighted previously, then save the
              // old inputValue so we can restore it on escape or terminal up-arrow.
              if (R.isNil(state.highlightedIndex) && 'inputValue' in mods) {
                mods = {
                  ...mods,
                  savedInputValue: state.inputValue,
                }
              }
            }
            break
        }

        log.debug?.('stateReducer', {state, changes, mods})
        return {...changes, ...mods}
      },
      [itemToString, results],
    )

    // Prefer React 18's useId to Downshift's built-in useId.
    const id = useId()

    return (
      <Downshift
        id={id}
        inputId={inputId}
        inputValue={value}
        itemToString={itemToString}
        onInputValueChange={handleInputChange}
        onOuterClick={handleOuterClick}
        onSelect={handleSelect}
        onStateChange={(changes, state) => {
          log.debug?.('onStateChange', {changes, state})
          if ('inputValue' in changes) {
            updateControlledValue(changes.inputValue)
          }
        }}
        stateReducer={stateReducer}
        {...rest}
      >
        {({
          closeMenu,
          getInputProps,
          getItemProps,
          getMenuProps,
          getRootProps,
          highlightedIndex,
          inputValue,
          isOpen,
          setState,
        }) => {
          const handleClearClick = () => {
            handleSelect()
            inputRef.current?.focus()
          }

          const handleKeyDown = event => {
            switch (event.key) {
              case 'Home':
              case 'End':
                // Default action: move within the dropdown.
                // Replacement action: move within the input box.
                event.nativeEvent.preventDownshiftDefault = true
                break

              case 'Enter':
                // Default action: select/close if open and highlighted, submit if
                // closed. Does not close on submit if nothing is highlighted.
                // Replacement action: preserve existing, but always close.
                closeMenu()
                break

              case 'Escape':
                // Default action: close menu and clear inputValue.
                // Replacement action: close menu and restore saved inputValue.
                // Ideally we would do this in stateReducer, but setting
                // changes.inputValue there doesn't seem to be effective.
                event.nativeEvent.preventDownshiftDefault = true
                closeMenu()
                setState(state => ({
                  ...(!R.isNil(state.savedInputValue) && {
                    inputValue: state.savedInputValue,
                    savedInputValue: null,
                  }),
                }))
                break
            }
          }

          const inputProps = getInputProps({
            autoComplete: 'off',
            autoCorrect: 'off',
            autoFocus,
            'data-testid': dataTestId,
            disabled,
            hasIcon: !!icon,
            name,
            onKeyDown: handleKeyDown,
            placeholder,
            ref,
            size,
            tabIndex: 0,
            variant: isOpen ? 'focused' : variant,
            ...rest,
          })

          return (
            <S.Wrapper {...getRootProps(rest)}>
              <S.InputWrapper>
                <S.Input {...inputProps} />
                {icon && (
                  <S.Icon size={size}>
                    {typeof icon === 'string' ?
                      <Icon icon={icon} />
                    : icon}
                  </S.Icon>
                )}
                <S.Indicators size={size}>
                  {inputValue && (
                    <S.DropDownIndicator as="div" size={size}>
                      <ClearButton onClick={handleClearClick} />
                    </S.DropDownIndicator>
                  )}
                </S.Indicators>
              </S.InputWrapper>
              <S.Menu
                {...getMenuProps({
                  style: isOpen && results.length ? {} : {display: 'none'},
                })}
              >
                {isOpen ?
                  results.map((item, index) => (
                    <S.Item
                      key={index}
                      {...getItemProps({
                        index,
                        isHighlighted: highlightedIndex === index,
                        item,
                      })}
                    >
                      {renderItem(item)}
                    </S.Item>
                  ))
                : null}
              </S.Menu>
              {!!hint && <S.Hint size={size}>{hint}</S.Hint>}
            </S.Wrapper>
          )
        }}
      </Downshift>
    )
  },
)

Search.type = 'search'

/*
Search.propTypes = {
  autoFocus: T.bool,
  disabled: T.bool,
  icon: T.oneOfType([T.componentType, T.string]),
  id: T.string,
  itemToString: T.func.isRequired,
  minChars: T.number,
  name: T.string,
  onChange: T.func,
  placeholder: T.string,
  renderItem: T.func.isRequired,
  search: T.func,
  size: T.sizeType,
  throttle: T.number,
  value: T.any,
  variant: T.variantType,
}
*/

export const StyledSearch = S.Wrapper
