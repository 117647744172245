import {ErrorBox} from 'quickstart/components/content/ErrorBox'
import {useMergedRefs} from 'quickstart/hooks'
import {space} from 'quickstart/theme'
import {wrapChildren} from 'quickstart/utils'
import {
  ComponentProps,
  forwardRef,
  Fragment,
  ReactNode,
  useCallback,
  useRef,
} from 'react'
import {RowContainer} from './layout'
import * as S from './styles'
import {getBaseType, getVariant} from './utils'

type LabelProps = ComponentProps<typeof S.Label>

/**
 * Field label, normally used by providing label as a text prop to Field.
 */
const Label = forwardRef<any, LabelProps>(({children, ...rest}, ref) => (
  <S.Label ref={ref} {...rest}>
    {
      // Wrap strings in span to allow for required asterisk.
      wrapChildren(children)
    }
  </S.Label>
))

interface FieldGroupProps extends ComponentProps<typeof S.FieldGroup> {
  label?: ReactNode
  required?: boolean
}

/**
 * Field group, for check lists and radios sharing field label and spacing.
 */
const FieldGroup = forwardRef<any, FieldGroupProps>(
  ({children, label, required, ...props}, ref) => (
    <S.FieldGroup {...props} ref={ref}>
      {label && (
        // @ts-expect-error as="legend" don't know
        <Label as="legend" required={required}>
          {label}
        </Label>
      )}
      {children}
    </S.FieldGroup>
  ),
)

interface FieldProps {
  children?: ReactNode
  component: (props: any) => JSX.Element
  // optional
  checked?: boolean
  connected?: boolean
  ['data-testid']?: string
  disabled?: boolean
  error?: ReactNode
  flexDirection?: 'column' | 'row'
  hint?: ReactNode
  id?: string
  label?: ReactNode
  modified?: boolean
  name?: string
  onChange?: (e: any) => void
  onClick?: (e: any) => void
  pristine?: boolean
  required?: boolean
  rightLabel?: ReactNode
  size?: any
  touched?: boolean
  type?: any
}

/**
 * Field component, wraps an actual form input to provide common handling of
 * labels, hints, layout, etc.
 */
const _Field = forwardRef<any, FieldProps>(
  (
    {
      checked,
      children,
      component: Component,
      connected,
      'data-testid': dataTestId,
      disabled,
      error,
      flexDirection,
      hint,
      id,
      label,
      modified,
      name,
      onChange,
      onClick,
      pristine,
      required,
      rightLabel,
      size = 'lg',
      touched,
      type,
      ...rest
    },
    forwardedRef,
  ) => {
    const baseType =
      type ||
      getBaseType(
        // @ts-expect-error
        Component.displayName,
      )
    const isRadio = baseType === 'radio'
    const isCheckbox = baseType === 'checkbox'
    const isCheckable = isRadio || isCheckbox
    const variant = getVariant({
      error,
      modified,
      isCheckbox,
      isRadio,
      touched,
      connected,
    })
    const isGroup = ['FieldGroup', 'RadioGroup'].includes(baseType)

    const isShowRequired = isRadio ? undefined : required
    const layout = flexDirection || (isCheckable ? 'row' : 'column')
    const Container = flexDirection === 'row' ? RowContainer : Fragment
    const uniqueId = isRadio ? id : id || name

    const inputRef = useRef()
    const ref = useMergedRefs([inputRef, forwardedRef])

    const handleClick = useCallback(
      (e: any) => {
        e.stopPropagation()
        onClick?.(e)
        if (isCheckbox) {
          e.target.checked = !e.target.checked
        }
        if (isCheckbox || isGroup) {
          onChange?.(e)
        }
      },
      [isCheckbox, isGroup, onChange, onClick],
    )

    const handleLabelClick = useCallback(() => {
      // @ts-expect-error
      inputRef.current?.focus?.()
    }, [inputRef])

    if (!Component) {
      return null
    }

    const field = (
      <Component
        checked={checked}
        connected
        data-testid={dataTestId}
        disabled={disabled}
        flexDirection={layout}
        id={uniqueId}
        label={label}
        name={name}
        onChange={onChange}
        onClick={handleClick}
        ref={ref}
        required={required}
        size={size}
        type={baseType}
        variant={variant}
        {...rest}
      >
        {children}
      </Component>
    )

    return (
      <S.Field
        checkableField={isCheckable}
        checked={checked}
        flexDirection={layout}
        {...rest}
      >
        <Container>
          {label && !isGroup && (
            <Label
              htmlFor={isCheckable ? undefined : uniqueId}
              onClick={handleLabelClick}
              required={isShowRequired}
            >
              {isCheckable && <S.Input>{field}</S.Input>}
              {!!rightLabel && <S.RightLabel>{rightLabel}</S.RightLabel>}
              {label}
            </Label>
          )}
          {!isCheckable && field}
          {!label && isCheckable && field}
        </Container>
        {error ?
          <ErrorBox style={{paddingLeft: space.lg}}>{error}</ErrorBox>
        : hint ?
          <S.Hint>{hint}</S.Hint>
        : null}
      </S.Field>
    )
  },
)

export const Field = Object.assign(_Field, {
  Group: FieldGroup,
  Hint: S.Hint,
  Label,
})

export {getBaseType}
