import {Link} from 'quickstart/components/content/Link'
import {MetaValue} from 'quickstart/components/tizra/MetaValue'
import {useMetaObj} from 'quickstart/hooks'
import {MetaProps, partitionMetaProps} from 'quickstart/utils'
import {ComponentProps, ReactNode, forwardRef} from 'react'
import {MetaObject, TocEntry, isPdfPage, isTocEntry, meta} from 'tizra'

interface MetaLinkProps extends MetaProps<MetaObject | TocEntry> {
  children?: ReactNode
  prop?: ComponentProps<typeof MetaValue>['prop']
  bypass?: 'default' | 'unlink' | 'elide'
  reader?: boolean
  variant?: ComponentProps<typeof Link>['variant']
}

export const MetaLink = forwardRef<HTMLAnchorElement, MetaLinkProps>(
  ({children, prop, bypass, reader, variant, ...restWithMetaProps}, ref) => {
    const [metaProps, rest] = partitionMetaProps(restWithMetaProps)
    const metaObj = useMetaObj(metaProps)

    children ||=
      isTocEntry(metaObj) ?
        metaObj.title
      : <MetaValue
          prop={prop || '_name'}
          {...metaProps} // pass through metaType for early useTypeDefs
          metaObj={metaObj} // reassure TS that metaObj is not a TocEntry
        />

    if (
      metaObj &&
      (isPdfPage(metaObj) || isTocEntry(metaObj) || metaObj.props.BypassToC)
    ) {
      if (bypass === 'elide') return null
      if (bypass === 'unlink')
        return (
          <span {...rest} ref={ref}>
            {children}
          </span>
        )
    }

    return (
      <Link
        href={meta.href(metaObj, {reader}) || undefined}
        variant={variant}
        {...rest}
        ref={ref}
      >
        {children ||
          (isTocEntry(metaObj) ?
            metaObj.title
          : <MetaValue
              prop={prop || '_name'}
              {...metaProps} // pass through metaType for early useTypeDefs
              metaObj={metaObj} // reassure TS that metaObj is not a TocEntry
            />)}
      </Link>
    )
  },
)
