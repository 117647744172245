import {
  Button,
  ConnectedField,
  Field,
  Radios,
  SearchForm,
  SearchTermsInput,
  Section,
  Select,
  Stack,
  Text,
  TextContainer,
} from 'quickstart/components'
import {
  NestedFields,
  SearchField,
  formFieldKey,
  nestedFormFields,
} from 'quickstart/lib/search/config'
import {cleanOptions} from 'quickstart/lib/search/options'
import {space} from 'quickstart/theme'
import {useMemo} from 'react'
import {logger} from 'tizra'
import {UseSearchReturn} from '../block'

const log = logger('SearchBlock/Advanced')

/* TODO
 * search tips
 * field.loading
 * field.sublabel
 * inline radio
 * year range
 * hide quick search
 * switch to results
 */

interface AdvancedConfigFieldProps {
  field: SearchField | NestedFields
  search: UseSearchReturn
}

const AdvancedConfigField = (props: AdvancedConfigFieldProps) => {
  let {field, search} = props

  // Following the rules of hooks, run this even when field is an array (nested
  // group of fields). The main reason we are memoizing here is for
  // cleanOptions.
  field = useMemo(
    () =>
      Array.isArray(field) ? field : (
        {
          multi: Array.isArray(field.defaultValue),
          widget: 'select',
          ...field,
          options: cleanOptions(field.options),
        }
      ),
    [field],
  )

  if (Array.isArray(field)) {
    return (
      <Field.Group>
        {field.map(f => (
          <AdvancedConfigField {...props} field={f} key={formFieldKey(f)} />
        ))}
      </Field.Group>
    )
  }

  switch (field.widget) {
    case 'input':
      return (
        <ConnectedField
          autoFocus
          component={SearchTermsInput}
          label={field.label || 'Search terms'}
          name={field.name}
          placeholder={field.placeholder}
          search={search}
          tips={field.tips}
        />
      )

    case 'radio':
      return (
        <ConnectedField
          component={Radios}
          label={field.label}
          name={field.name}
          options={field.options}
        />
      )

    case 'select':
      return (
        <ConnectedField
          component={Select}
          label={field.label}
          multiple={field.multi}
          name={field.name}
          options={field.options}
          placeholder={field.placeholder || 'Any'}
          search={false /* field.multi */}
        />
      )

    /*
    case 'year-range':
      return (
        <Final.Field
          name={field.name}
          render={renderProps => (
            <SuiYearRange
              {...renderProps}
              id={field.id}
              label={field.label}
              options={field.options}
              loading={field.loading}
              wide
            />
          )}
        />
      )
      */

    default:
      log.error(`No widget for name=${field.name} widget=${field.widget}`)
      return null
  }
}

interface AdvancedProps {
  search: UseSearchReturn
}

export const Advanced = ({
  search,
  search: {config, defaultParams},
}: AdvancedProps) => {
  const fields = nestedFormFields(config, 'advancedSearch')

  return (
    <Section
      heading={
        <Text variant="h1" style={{marginBottom: space.xxl}}>
          Advanced Search
        </Text>
      }
    >
      <TextContainer variant="wider">
        <SearchForm search={search}>
          {
            // @ts-expect-error
            ({form: {reset}}) => (
              <Stack spacing="xxl">
                <Button
                  variant="secondary"
                  onClick={() => reset(defaultParams)}
                >
                  Start over
                </Button>
                {fields.map(f => (
                  <AdvancedConfigField
                    key={formFieldKey(f)}
                    field={f}
                    search={search}
                  />
                ))}
                <Button type="submit">Search</Button>
              </Stack>
            )
          }
        </SearchForm>
      </TextContainer>
    </Section>
  )
}
