import {useMergedRefs} from 'quickstart/hooks'
import {forwardRef, useEffect, useRef} from 'react'
import {logger} from 'tizra'
import * as S from './styles'

const log = logger('Input')

export const Input = forwardRef(({autoFocus, ...rest}, forwadedRef) => {
  // Autofocus
  const inputRef = useRef()
  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus()
    }
  }, [autoFocus])

  // Merge refs for passing through
  const ref = useMergedRefs([forwadedRef, inputRef])

  return <S.Input {...rest} ref={ref} />
})

/*
Input.propTypes = {
  autoFocus: T.bool,
  disabled: T.bool,
}
*/
