import classNames from 'classnames'
import {useHydrating, useMergedRefs} from 'quickstart/hooks'
import styled, {
  SystemProps,
  css,
  system,
  th,
} from 'quickstart/styled-components/system'
import * as R from 'rambdax'
import {ComponentProps, ReactNode, forwardRef, useMemo, useRef} from 'react'
import {
  logger,
  simplifyHtml,
  simplifyTocHtml,
  stripScripts,
  stripTags,
} from 'tizra'
import {useScriptRunner} from './useScriptRunner'

const log = logger('Html')

// exported for tests
export const variants = {
  customer: {
    simplify: simplifyHtml,
    strip: stripTags,
  },
  raw: {
    simplify: R.identity<string>,
    strip: stripTags,
  },
  dangerous: {
    simplify: R.identity<string>,
    strip: stripScripts,
  },
  snippet: {
    simplify: stripScripts,
    strip: stripTags,
  },
  strip: {
    simplify: stripTags,
    strip: stripTags,
  },
  toc: {
    simplify: simplifyTocHtml,
    strip: stripTags,
  },
}

type HtmlVariant = keyof typeof variants

const HtmlProse = styled.div<SystemProps & {$linksVariant?: string}>(
  ({$linksVariant}) => css`
    &.customer-html > *:last-child,
    &.customer-html > *:last-child > *:last-child,
    &.customer-html > *:last-child > *:last-child > *:last-child {
      margin-bottom: 0 !important;
    }

    // This overrides links.default set in customerStyles via customer-html.
    // Unfortunately we need the double ampersand to increase specificity.
    ${$linksVariant &&
    css`
      && a {
        ${th(`links.${$linksVariant}`)};
      }
    `}

    ${system}
  `,
)

type HtmlProps = Omit<
  ComponentProps<typeof HtmlProse>,
  'children' | 'html' | '$linksVariant'
> & {
  children?: ReactNode
  html?: string
  links?: ComponentProps<typeof HtmlProse>['$linksVariant']
  reset?: boolean
  variant?: HtmlVariant
  _eval?: any // for tests
}

export const Html = forwardRef<any, HtmlProps>(
  (
    {
      as = 'span',
      html: rawHtml,
      reset,
      links,
      variant = 'customer',
      _eval,
      children,
      ...props
    },
    forwardedRef,
  ) => {
    log.assert(!children || !rawHtml, 'received both children and html')

    const myRef = useRef<HTMLElement>(null)
    const mergedRef = useMergedRefs([forwardedRef, myRef])
    const stage = useHydrating() ? 'strip' : 'simplify'
    const cook = variants[variant][stage]
    const cookedHtml = useMemo<string>(
      () => cook(rawHtml || ''),
      [cook, rawHtml],
    )
    const readyHtml = useScriptRunner({html: cookedHtml, ref: myRef, _eval})

    const contentProps =
      children ? {children} : {dangerouslySetInnerHTML: {__html: readyHtml}}

    return (
      <HtmlProse
        {...props}
        as={as}
        className={classNames(
          reset && 'customer-reset',
          'customer-html',
          props.className,
        )}
        $linksVariant={links}
        {...contentProps}
        ref={mergedRef}
      />
    )
  },
)
