import {ComponentProps, forwardRef} from 'react'
import {meta} from 'tizra'
import {CartItem as TCartItem} from 'tizra/types'
import * as B from '../block'

type CartItemProps = ComponentProps<typeof B.Summary> &
  TCartItem & {
    onRemove?: () => void
    variant?: 'cart' | 'checkout'
  }

export const CartItem = forwardRef<unknown, CartItemProps>(
  ({item, offer, onRemove, variant = 'cart', ...props}, ref) => {
    const price =
      offer.isFree ? 'Free' : (
        <B.Currency
          amount={offer.price}
          currency={offer.currencyInfo || 'USD'}
        />
      )
    return (
      <B.Summary ref={ref} {...props}>
        <B.Summary.Cover
          as={B.UniversalLink}
          href={meta.href(item) || undefined}
          width={variant === 'cart' ? '100px' : '80px'}
        >
          <B.MetaThumb metaObj={item} />
        </B.Summary.Cover>
        <B.Summary.Body>
          <B.LeftRight>
            <div>
              {variant === 'cart' ?
                <B.Summary.Heading>
                  <B.MetaLink metaObj={item} />
                </B.Summary.Heading>
              : <B.MetaValue prop="_name" metaObj={item} />}
              {variant === 'cart' && <B.Text>{price}</B.Text>}
            </div>
            {variant === 'cart' ?
              <B.ClearButton onClick={onRemove} size="xs" />
            : price}
          </B.LeftRight>
        </B.Summary.Body>
      </B.Summary>
    )
  },
)
