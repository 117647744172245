import {ComponentProps, ComponentPropsWithoutRef, useRef} from 'react'
import {logger} from 'tizra'
import * as B from '../block'
import {
  FooterAdmin,
  FooterGlobalAdmin,
  defaultGlobalConfig,
  globalMigrate,
} from './admin'

const log = logger('FooterBlock')

const Footer = (props: ComponentPropsWithoutRef<'div'>) => {
  const ref = useRef<any>()

  B.useIsomorphicLayoutEffect(() => {
    if (ref.current) {
      if (document.body.id !== 't-my') {
        log.warn('rendering outside cubchicken layout, wish me luck')
      }
      const div: HTMLDivElement | null = ref.current.closest(
        '.t-quickstart-block',
      )
      if (!div) {
        log.warn("can't find wrapping t-quickstart-block, can't stick footer")
        return
      }
      // If the footer is wrapped in a stacked block, or a root wrapper div, try
      // to shuffle it out so we can stick it to the bottom of the page.
      if (document.body.id === 't-my' && div.parentElement !== document.body) {
        log.log(
          "t-quickstart-block isn't direct child of body, attempting to eject from stack",
        )
        div.parentElement
          ?.closest('body > *')
          ?.insertAdjacentElement('afterend', div)
      }
      Object.assign(div.style, {
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
      })
    }
  }, [ref])

  return <footer ref={ref} {...props} />
}

type FooterRowProps = ComponentProps<typeof B.Section>

const FooterRow = (props: FooterRowProps) => (
  <B.Scene reveal>
    {({style}) => (
      <div style={style}>
        <B.Divider />
        <B.Section {...props} />
      </div>
    )}
  </B.Scene>
)

const FooterLink = (
  props: B.NavLinkProps | ({type?: never} & B.NavLinkLinkProps),
) => (
  <B.Scene>
    <B.NavLink type="link" {...props} />
  </B.Scene>
)

export const FooterBlock = B.blockWithInfo(
  {
    name: 'footer',
    displayName: 'FooterBlock',
    title: 'Footer',
    Admin: FooterAdmin,
    GlobalAdmin: FooterGlobalAdmin,
    defaultGlobalConfig,
    globalMigrate,
    dividers: false, // FooterRow supplies its own
  },
  () => {
    const {alignment, copyright, items} = B.useGlobalConfig(FooterBlock)
    return (
      <B.Scene root>
        <Footer>
          <FooterRow variant="skinny">
            <B.Container>
              <B.NavLink.Group variant="nav" alignment={alignment}>
                {items
                  .filter(
                    item => item.type === 'link' && item.label && item.href,
                  )
                  .map((props, i) => (
                    <FooterLink key={i} {...props} />
                  ))}
              </B.NavLink.Group>
            </B.Container>
          </FooterRow>
          <FooterRow variant="superskinny">
            <B.Container>
              <B.NavLink.Group variant="navMeta" alignment={alignment}>
                <span>
                  {copyright && (
                    <B.Scene>
                      <B.Html html={copyright} links="unstyled" variant="raw" />{' '}
                      &mdash;{' '}
                    </B.Scene>
                  )}
                  <FooterLink
                    href="https://tizra.com"
                    // @ts-expect-error system props not in typing
                    fontWeight="inherit"
                  >
                    Powered by Tizra<sup>®</sup>
                  </FooterLink>
                </span>
              </B.NavLink.Group>
            </B.Container>
          </FooterRow>
        </Footer>
      </B.Scene>
    )
  },
)
