import {Html} from 'quickstart/components/content/Html'
import {Summary} from 'quickstart/components/content/Summary'
import {UniversalLink} from 'quickstart/components/content/UniversalLink'
import {Breadcrumbs} from 'quickstart/components/layout/Breadcrumbs'
import {MetaThumb} from 'quickstart/components/tizra/MetaThumb'
import {MetaValue} from 'quickstart/components/tizra/MetaValue'
import {useApi, useMergedRefs, useMetaObj, useVisible} from 'quickstart/hooks'
import {useUp} from 'quickstart/styled-components/system'
import {truish} from 'quickstart/types'
import {ComponentProps, forwardRef, useMemo} from 'react'
import {
  IS_TEST,
  MetaObject,
  SearchResult,
  TocEntry,
  isExcerpt,
  isPdfPage,
  isTocEntry,
  logger,
  meta,
  tocHierarchy,
} from 'tizra'
import * as S from './styles'

const log = logger('MetaSummary')

type MetaSummaryProps = ComponentProps<typeof Summary> & {
  metaObj: SearchResult | MetaObject | TocEntry
  linkOptions?: Parameters<typeof meta.href>[1]
  thumbnail?: boolean
  titlingPropPrefix?: string
}

export const MetaSummary = forwardRef<any, MetaSummaryProps>(
  (
    {metaObj, linkOptions, thumbnail = true, titlingPropPrefix, ...rest},
    forwardedRef?: any,
  ) => {
    const isLaptop = useUp('lg', false)
    const [isVisible, visibilityRef] = useVisible({sticky: true})
    const ref = useMergedRefs([forwardedRef, visibilityRef])

    const prefixed = (suffix: string) =>
      titlingPropPrefix ? `${titlingPropPrefix}${suffix}` : ''

    // Attach parent to metaObj, especially for toc-entry to get cover images,
    // and meta.href to link by url-id instead of tizra-id.
    metaObj = useMetaObj({metaObj, attachParent: true, enabled: isVisible})!
    const parentId = meta.parentId(metaObj)

    const href =
      meta.string(metaObj, prefixed('Link')) ||
      // For results with a parent (PdfPage, PageRange, toc-entry) link to the
      // page in the reader.
      meta.href(metaObj, linkOptions) ||
      undefined

    // Fetch the toc for clickable hierarchy breadcrumbs.
    const {data: toc} = useApi.toc(isVisible && parentId && {tizraId: parentId})
    const foundIn = useMemo<TocEntry[] | undefined>(
      () =>
        tocHierarchy(toc, metaObj)?.map(x => ({
          // Attach parent for the sake of meta.href linking by url-id instead of
          // tizra-id
          parent: metaObj?.parent,
          ...x,
        })),
      [metaObj, toc],
    )

    // toc-entry has logicalPage and PdfPage has logicalPageNumber, but excerpts
    // need help from the logical-pages API.
    const logicalPage =
      metaObj &&
      (('logicalPage' in metaObj && metaObj.logicalPage) ||
        ('logicalPageNumber' in metaObj && metaObj.logicalPageNumber))
    const {data: logicalPages} = useApi.logicalPages(
      isVisible && isExcerpt(metaObj) && parentId && {tizraId: parentId},
    )
    const pageInfo =
      logicalPage ? `page ${logicalPage}`
      : isExcerpt(metaObj) ? meta.formatPages(metaObj.pages, logicalPages)
      : undefined

    // Breadcrumbs is strict about children, so clean them up ahead of time.
    const breadcrumbs =
      !foundIn?.length ?
        null
      : [
          metaObj.parent && (
            <Breadcrumbs.Item href={meta.href(metaObj.parent)} key="crust">
              <MetaValue
                metaObj={metaObj.parent}
                prop={[
                  prefixed('BreadcrumbsTitle'),
                  'BreadcrumbsTitle',
                  prefixed('Title'),
                  '_name',
                ]}
              />
            </Breadcrumbs.Item>
          ),
          ...foundIn.slice(0, -1).map((entry, i) => (
            <Breadcrumbs.Item key={i} href={meta.href(entry, linkOptions)}>
              <Html html={entry.title} variant="toc" />
            </Breadcrumbs.Item>
          )),
        ].filter(truish)
    const footer =
      !breadcrumbs?.length ?
        null
      : <Summary.Footer>
          <S.FoundIn>Found in:</S.FoundIn>
          <Breadcrumbs style={{display: 'inline'}}>{breadcrumbs}</Breadcrumbs>
        </Summary.Footer>

    return (
      <Summary
        as={UniversalLink}
        // @ts-expect-error Summary as UniversalLink should accept href
        href={href}
        {...rest}
        ref={ref}
        {...(IS_TEST && {'data-test-is-visible': 'true'})}
      >
        {thumbnail && (isLaptop || !meta.snippet(metaObj)) && (
          <Summary.Cover>
            <MetaThumb minRatio={8.5 / 11} metaObj={metaObj} />
          </Summary.Cover>
        )}
        <Summary.Body>
          <Summary.Heading>
            {foundIn?.length ?
              <Html html={foundIn[foundIn.length - 1].title} variant="toc" />
            : isTocEntry(metaObj) ?
              <Html html={metaObj.title} variant="toc" />
            : <MetaValue
                metaObj={
                  isPdfPage(metaObj) || isTocEntry(metaObj) ?
                    metaObj.parent
                  : metaObj
                }
                prop={[prefixed('Title'), '_name']}
              />
            }
          </Summary.Heading>
          <MetaSummaryDescription metaObj={metaObj} pageInfo={pageInfo} />
          {footer}
        </Summary.Body>
      </Summary>
    )
  },
)

type MetaSummaryDescriptionProps = {
  metaObj: MetaObject | SearchResult | TocEntry
  pageInfo?: string | undefined
  titlingPropPrefix?: string
}

function MetaSummaryDescription({
  metaObj,
  pageInfo,
  titlingPropPrefix,
}: MetaSummaryDescriptionProps) {
  const snippetHtml = meta.snippet(metaObj)
  const snippet =
    snippetHtml ? <Html html={snippetHtml} variant="snippet" /> : null
  const px = (suffix: string) =>
    titlingPropPrefix ? `${titlingPropPrefix}${suffix}` : ''
  const authors =
    isExcerpt(metaObj) || isTocEntry(metaObj) ?
      null
    : <MetaValue metaObj={metaObj} prop={[px('Snippet'), 'Authors']} as="div" />
  return (
    <Summary.Description>
      {snippet || authors}
      {pageInfo && <Summary.PageInfo>{pageInfo}</Summary.PageInfo>}
    </Summary.Description>
  )
}
