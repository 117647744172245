import {useIsomorphicLayoutEffect} from 'quickstart/hooks/useIsomorphicLayoutEffect'
import {GlobalStyle} from 'quickstart/styled-components/theme/global'
import * as T from 'quickstart/theme'
import {truthy} from 'quickstart/types'
import {magicSort} from 'tizra'

const makeFontUrl = ({name, weights}: {name: string; weights: string[]}) => {
  const italics = [0, 1]
  const usp = new URLSearchParams({
    family: `${name}:ital,wght@${italics
      .flatMap(i => weights.map(w => `${i},${w}`))
      .join(';')}`,
    display: 'swap',
  })
  return `https://fonts.googleapis.com/css2?${usp}`
}

type Stringified<T extends string | number> = `${T}`

const sortedUniqueValues = <T extends string | number>(
  x: Record<string, T>,
): Stringified<T>[] => {
  const values = Object.values(x)
    .filter(truthy)
    .map(x => `${x}`) as Stringified<T>[]
  const unique = Array.from(new Set(values))
  return magicSort<Stringified<T>>(x => x)(unique)
}

export const StyledComponentsGlobalStyle = () => <GlobalStyle />

export const LinariaGlobalStyle = () => {
  const theme = T.useTheme()
  const fontNames = sortedUniqueValues(theme.baseFonts)
  const urls = fontNames
    .filter(name => name in T.fontSpecs)
    .map(name => {
      const weights = sortedUniqueValues(T.fontSpecs[name].weights)
      return makeFontUrl({name, weights})
    })

  useIsomorphicLayoutEffect(() => {
    for (const [k, v] of Object.entries(theme.fontMap)) {
      document.body.style.setProperty(k, v)
    }
    document.body.style.setProperty(
      T.cssVars.colors.link,
      theme.colors.linkColor,
    )
    document.body.style.setProperty(
      T.cssVars.colors.underline,
      theme.colors.textUnderline,
    )
  }, [theme])

  return (
    <>
      {urls.map((url, i) => (
        <link href={url} rel="stylesheet" key={i} />
      ))}
    </>
  )
}
