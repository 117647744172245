import * as AK from '@ariakit/react'
import {Field} from 'quickstart/components/controls/Field'
import {Stack} from 'quickstart/components/layout/Stack'
import {dataProps} from 'quickstart/theme'
import {ChangeEvent, ComponentProps, ReactNode} from 'react'
import * as S from './styles'

interface RadiosProps {
  name: string
  label?: ComponentProps<typeof Field.Group>['label']
  onChange?: (v: string) => void
  options?: Array<{value: string; label: ReactNode; disabled?: boolean}>
  spacing?: ComponentProps<typeof Stack>['spacing']
  value?: string
  disabled?: boolean
}

export const Radios = ({
  name,
  label,
  onChange,
  options = [],
  spacing = 'xs',
  value,
  disabled: allDisabled,
}: RadiosProps) => {
  const store = AK.useRadioStore({value})
  return (
    <AK.RadioGroup
      store={store}
      render={({children, ...renderProps}) => (
        <Field.Group label={label} {...renderProps}>
          <Stack spacing={spacing}>{children}</Stack>
        </Field.Group>
      )}
    >
      {options.map((o, i) => {
        const checked = value === o.value
        const disabled = allDisabled || o.disabled
        return (
          <S.Wrapper {...dataProps({disabled})} key={i}>
            <AK.Radio
              name={name}
              value={o.value}
              checked={checked}
              render={props => (
                // S.Wrapper is display:grid, and S.Radio attempts
                // vertical-align:baseline. This seems to require a div wrapper
                // to act as the grid item.
                <S.RadioColumn>
                  <S.Radio disabled={disabled} {...props} />
                </S.RadioColumn>
              )}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                e.target.checked && onChange?.(o.value)
              }
              disabled={disabled}
            />
            <S.Label {...dataProps({checked})}>{o.label}</S.Label>
          </S.Wrapper>
        )
      })}
    </AK.RadioGroup>
  )
}
