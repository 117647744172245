import {cssVars, dataProps, transient} from 'quickstart/theme'
import {UnprefixStyledProps} from 'quickstart/types'
import {ComponentProps, forwardRef} from 'react'
import * as S from './styles'

const _Digest = forwardRef<any, ComponentProps<typeof S.Digest>>(
  (props, ref) => <S.Digest {...props} ref={ref} />,
)

type GroupProps = Partial<
  UnprefixStyledProps<ComponentProps<typeof S.Group>>
> & {linkColor?: string}

const Group = forwardRef<any, GroupProps>(
  ({background, foreground, linkColor, size = 'large', ...props}, ref) => (
    <S.Group
      {...props}
      {...dataProps({size})}
      {...transient({background, foreground})}
      style={{
        ...(linkColor && {[cssVars.colors.link]: linkColor}),
        ...props.style,
      }}
      ref={ref}
    />
  ),
)

export const Digest = Object.assign(_Digest, {
  Heading: S.Heading,
  Snippet: S.Snippet,
  Group,
})
