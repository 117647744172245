import {Html, Icon} from 'quickstart/components/content'
import {Button, Search} from 'quickstart/components/controls'
import {Box, Modal} from 'quickstart/components/layout'
import {UseSearchReturn, useAutoComplete, useMarkdown} from 'quickstart/hooks'
import * as R from 'rambdax'
import {ComponentProps, forwardRef} from 'react'
import {logger} from 'tizra'

const log = logger('SearchTermsInput')

type SearchTermsInputProps = ComponentProps<typeof Search> & {
  search: UseSearchReturn
  tips?: string
}

export const SearchTermsInput = forwardRef<unknown, SearchTermsInputProps>(
  ({search: {config}, tips, ...rest}, ref) => {
    const autocomplete = useAutoComplete(config)

    const modal = Modal.useStore()
    const tipsHtml = useMarkdown(tips) || ''
    const hint =
      !tips ? undefined : (
        <Box>
          <Button onClick={modal.show} variant="stealth" color="linkColor">
            <Icon icon="info" />
            See tips
          </Button>{' '}
          for using special search characters
        </Box>
      )

    return (
      <>
        <Search
          // @ts-expect-error
          itemToString={R.prop('full')}
          ref={ref}
          // @ts-expect-error
          renderItem={item => (
            <span>
              {item.before}
              <strong>{item.suggestion}</strong>
              {item.after}
            </span>
          )}
          search={autocomplete}
          hint={hint}
          {...rest}
        />
        <Modal aria-label="search tips" store={modal}>
          <Modal.Title>Search Tips</Modal.Title>
          <Modal.Content>
            <Html html={tipsHtml} variant="raw" />
          </Modal.Content>
        </Modal>
      </>
    )
  },
)
