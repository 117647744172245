import {themeGetter} from 'quickstart/styled-components/system'

const getAccordions = (theme: any) => {
  const th = themeGetter(theme)
  return {
    padding: th('space.borderedBoxPaddingSm'),
    wrapper: {
      backgroundColor: th('colors.backgroundColorWhite'),
      borderColor: th('colors.borderColor'),
      borderRadius: th('radii.none'),
      borderStyle: 'solid',
      borderWidth: th('borderWidths.sm'),
    },
    title: {
      ...th('ui.facetHead'),
      color: th('colors.textColorMuted'),
    },
    icon: {
      color: th('colors.textColorMuted'),
      // fontSize: '.75em', before we switched to Feather
    },
  }
}

export default getAccordions
