import {transient} from 'quickstart/styled-components/system'
import {UnprefixTransientProps} from 'quickstart/types'
import {ComponentProps, forwardRef} from 'react'
import * as S from './styles'

type SummaryProps = UnprefixTransientProps<ComponentProps<typeof S.Summary>>

const _Summary = forwardRef<any, SummaryProps>(({clickable, ...props}, ref) => (
  <S.Summary {...props} {...transient({clickable})} ref={ref} />
))

export const Summary = Object.assign(_Summary, {
  displayName: 'Summary',
  Cover: S.Cover,
  Body: S.Body,
  Footer: S.Footer,
  Heading: S.Heading,
  Description: S.Description,
  PageInfo: S.PageInfo,
})
