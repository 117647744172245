import * as React from 'react'
import {ComponentProps, ReactNode, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {logger, meta} from 'tizra'
import {useAuthConfig} from '../SignInBlock'
import * as B from '../block'
import {CartItem} from './CartItem'
import {Config} from './admin'
import * as S from './styles'

const log = logger('CartBlock/Checkout')

type CheckoutWizardProps = {
  config: Config
  userData: Exclude<ReturnType<typeof B.useUserData>, null>
}

interface Step {
  title: string
  Component: any
  props?: any
}

interface CheckoutStepProps {
  active: boolean
  config: Config
  currentIndex: number
  setIndex: (i: number) => void
  myIndex: number
  steps: Step[]
  userData: Exclude<ReturnType<typeof B.useUserData>, null>
  proceed: () => void
}

const SignIn = ({active, proceed, userData}: CheckoutStepProps) => {
  const authConfig = useAuthConfig()
  const [view, setView] =
    useState<ComponentProps<typeof B.Auth>['view']>('signin')

  if (userData === null) {
    // Waiting for response from /api/logged-in-users
    return null
  }

  const signedInAs =
    userData ?
      <B.Text>Signed in as {meta.userCheckoutName(userData)}</B.Text>
    : <B.Text>Not signed in.</B.Text>

  if (!active) {
    // This part of the Checkout accordion is closed.
    return signedInAs
  }

  if (!userData) {
    // We are active and signed out. Need to sign in!

    // TODO this is hacky, we're missing an abstraction somewhere.
    if (typeof window !== 'undefined' && window.tizra.customLoginUrl) {
      return (
        <B.Text>
          Please <B.Link onClick={() => window.tizra.login()}>sign in</B.Link>{' '}
          to continue.
        </B.Text>
      )
    }

    return (
      <B.Auth
        {...authConfig}
        autoFocus
        modal={false}
        show={setView}
        view={view}
        variant="checkout"
      />
    )
  }

  // We are active and signed in. Are you sure you want to sign out?
  return (
    <B.FormShell
      variant="fluid"
      primaryButton={{
        children: 'Sign out',
        onClick: () => void window.tizra.logout(),
      }}
      secondaryButtons={[{children: 'Cancel', onClick: () => void proceed()}]}
    >
      <B.Prose>
        {signedInAs}
        <B.Text>
          <strong>
            If you sign out, you'll need to re-add items to your shopping bag.
          </strong>
        </B.Text>
      </B.Prose>
    </B.FormShell>
  )
}

const FulfillerButtons = () => {
  const [payPalForm, setPayPalForm] = useState<HTMLFormElement | null>(null)
  const [testForm, setTestForm] = useState<HTMLFormElement | null>(null)
  const [freeLink, setFreeLink] = useState<HTMLAnchorElement | null>(null)

  useEffect(() => {
    setPayPalForm(document.forms.namedItem('payPalForm'))
    setTestForm(
      document.querySelector(
        'form[action="/commerce/fulfiller_hardcoded"]',
      ) as HTMLFormElement,
    )
    setFreeLink(
      document.querySelector(
        'a[href^="/commerce/"][href$="/fulfiller_free"]',
      ) as HTMLAnchorElement,
    )
  }, [])

  if (!payPalForm && !testForm && !freeLink) {
    return null
  }

  return (
    <B.Stack spacing="xl" divided>
      {payPalForm && (
        <B.Stack spacing="xl">
          <B.Text>
            No PayPal account required—you will have the option at the PayPal
            site to use a credit card.
          </B.Text>
          <B.Button onClick={() => void payPalForm.submit()}>
            Purchase with PayPal
          </B.Button>
        </B.Stack>
      )}
      {freeLink && (
        <B.Stack spacing="xl">
          <B.Button onClick={() => void freeLink.click()}>
            Complete free purchase
          </B.Button>
        </B.Stack>
      )}
      {testForm && (
        <B.Stack spacing="xl">
          <B.Button onClick={() => void testForm.submit()}>
            Purchase with Test Fulfiller
          </B.Button>
        </B.Stack>
      )}
    </B.Stack>
  )
}

const CIRCLED_NUMBERS = [
  '⓪',
  '①',
  '②',
  '③',
  '④',
  '⑤',
  '⑥',
  '⑦',
  '⑧',
  '⑨',
] as const

const NumberedHeading = ({
  number,
  children,
}: {
  children: ReactNode
  number: number
}) => (
  <S.NumberedHeading>
    <div>{CIRCLED_NUMBERS[number]}</div>
    <div>{children}</div>
  </S.NumberedHeading>
)

const CheckoutWizard = ({config, userData, ...props}: CheckoutWizardProps) => {
  const [currentIndex, setIndex] = React.useState<number>(userData ? 1 : 0)

  const proceed = React.useCallback(
    () => setIndex(currentIndex => currentIndex + 1),
    [setIndex],
  )

  const steps: Step[] = [
    {title: 'Account', Component: SignIn},
    {title: 'Payment', Component: FulfillerButtons},
  ]

  return (
    <B.Stack divided endCapped spacing="lg" {...props}>
      {steps.map(({title, Component, props}, myIndex) => (
        <B.Stack key={myIndex}>
          <B.LeftRight>
            <NumberedHeading number={myIndex + 1}>{title}</NumberedHeading>
            {currentIndex > myIndex && (
              <B.Text variant="textMd">
                <B.Link onClick={() => setIndex(myIndex)}>Edit</B.Link>
              </B.Text>
            )}
          </B.LeftRight>
          <Component
            active={currentIndex === myIndex}
            userData={userData}
            config={config}
            steps={steps}
            setIndex={setIndex}
            myIndex={myIndex}
            currentIndex={currentIndex}
            proceed={proceed}
            {...props}
          />
          {myIndex === 2 && currentIndex === 3 && <B.Button>Purchase</B.Button>}
        </B.Stack>
      ))}
    </B.Stack>
  )
}

interface OrderSummaryProps {
  config: Config
}

const OrderSummary = ({config}: OrderSummaryProps) => {
  const navigate = useNavigate()
  const {data: cart} = B.useApi.cart()

  if (!cart) {
    return null
  }

  const subtotal = cart.reduce(
    (sum: number, {offer: {price}}: any) => sum + price,
    0,
  )

  const currency =
    (cart[0] &&
      log.assert(
        cart[0].offer.currencyInfo,
        'missing currency-info in cart API response',
      )) ||
    'USD'

  return (
    <B.Stack spacing="xxl">
      <S.BorderedStack spacing="xxl">
        <B.LeftRight>
          <B.Text variant="h5">Order Summary</B.Text>
          <B.Text variant="textMd">
            <B.Link onClick={() => navigate('#cart')}>Edit bag</B.Link>
          </B.Text>
        </B.LeftRight>
        <B.Stack spacing="xs" divided>
          <B.Text>{cart?.length} items</B.Text>
          <B.Stack>
            {cart?.map((x: any, i: number) => (
              <CartItem {...x} variant="checkout" key={i} />
            ))}
          </B.Stack>
        </B.Stack>
      </S.BorderedStack>
      <B.Stack capped>
        <B.LeftRight>
          <B.Text variant="h5" as="div">
            Subtotal (USD)
          </B.Text>
          <B.Text variant="h5" as="div">
            <B.Currency amount={subtotal} currency={currency} />
          </B.Text>
        </B.LeftRight>
      </B.Stack>
    </B.Stack>
  )
}

interface CheckoutProps {
  config: Config
}

export const Checkout = ({config, ...props}: CheckoutProps) => {
  const userData = B.useUserData()

  return (
    <B.Section {...props}>
      <B.Grid cols={{_: 1, lg: 2}} colGap="48px">
        {userData === null ?
          <div />
        : <CheckoutWizard config={config} userData={userData} />}
        <OrderSummary config={config} />
      </B.Grid>
    </B.Section>
  )
}
