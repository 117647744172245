import {transient} from 'quickstart/styled-components/system'
import {UnprefixTransientProps} from 'quickstart/types'
import {ComponentProps, forwardRef} from 'react'
import * as S from './styles'

type LinkProps = UnprefixTransientProps<
  Omit<ComponentProps<typeof S.Link>, 'color'>
>

export const Link = forwardRef<any, LinkProps>(({variant, ...props}, ref) => (
  <S.Link {...transient({variant})} {...props} ref={ref} />
))
