import {dataProps, withProps} from 'quickstart/theme'
import {ComponentProps, forwardRef} from 'react'
import {Text} from 'quickstart/components/content/Text'
import * as S from './styles'

type CardProps = ComponentProps<typeof S.Card> & {
  stretch?: boolean
  clickable?: boolean
}

const _Card = forwardRef<any, CardProps>(
  ({clickable, stretch, ...props}, ref) => (
    <S.Card {...props} {...dataProps({clickable, stretch})} ref={ref} />
  ),
)

export const Card = Object.assign(_Card, {
  Cover: S.Cover,
  Body: S.Body,
  Footer: S.Footer,
  Meta: S.Meta,
  Heading: S.Heading,
  Description: withProps({variant: 'cardSnippet'})(Text),
  CallToAction: S.CallToAction,
})
