import {IconButton} from 'quickstart/components/controls/IconButton'
import {Opt} from 'quickstart/types'
import {ComponentProps, forwardRef} from 'react'

export const ClearButton = forwardRef<
  any,
  Opt<ComponentProps<typeof IconButton>, 'icon'>
>((props, ref) => (
  <IconButton
    color="textColorLight"
    hoverable={false}
    icon="close"
    size="xs"
    title="Clear"
    {...props}
    ref={ref}
  />
))
