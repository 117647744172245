import {ComponentProps, forwardRef} from 'react'
import * as S from './styles'
import {UnprefixStyledProps} from 'quickstart/types'
import {SetOptional} from 'type-fest'

export const Shape = forwardRef<
  any,
  SetOptional<UnprefixStyledProps<ComponentProps<typeof S.Shape>>, 'shape'>
>(({shape = 'rectangle', ...props}, ref) => (
  <S.Shape $shape={shape} {...props} ref={ref} />
))
