import * as R from 'rambdax'
import {PartialDeep} from 'type-fest'
import {Branding} from '../types'

// content
import getImages from 'quickstart/components/content/Image/theme'
import getLinks from 'quickstart/components/content/Link/theme'
import getSummaries from 'quickstart/components/content/Summary/theme'
import getTiles from 'quickstart/components/content/Tile/theme'

// controls
import getButtons from 'quickstart/components/controls/Button/theme'
import getCheckboxes from 'quickstart/components/controls/Checkbox/theme'
import getDropdownMenus from 'quickstart/components/controls/DropdownMenu/theme'
import getFields from 'quickstart/components/controls/Field/theme'
import getIconButtons from 'quickstart/components/controls/IconButton/theme'

// layout
import getAccordions from 'quickstart/components/layout/Accordion/theme'
import getFlexGrids from 'quickstart/components/layout/FlexGrid/theme'
import getListDefinitions from 'quickstart/components/layout/ListDefinition/theme'
import getModals from 'quickstart/components/layout/Modal/theme'
import getNav from 'quickstart/components/layout/Nav/theme'
import getNavLink from 'quickstart/components/layout/NavLink/theme'
import {gaps, transitions} from 'quickstart/theme/constants'
import {
  containers,
  textContainers,
  typography,
} from 'quickstart/theme/typography'

import {getColorScales, getColors} from './colors'
import {getFonts} from './fonts'

// These functions should always be based on 16px even if the base font size
// changes, since the purpose is to make consistent rems or ems from familiar
// pixel values. The resulting values will scale properly just as if we had used
// rems or ems directly.
const toRem = (px: number) => `${px / 16}rem`
const toEm = (px: number) => `${px / 16}em`
const toPx = (s: string | number) =>
  typeof s !== 'string' ? s
  : s.endsWith('em') ? parseInt(s) * 16
  : parseInt(s)

// Breakpoints are used by system components, see
// https://styled-system.com/responsive-styles
const _breakpoints = {
  // xs: 0, "Any undefined alias key will define the base value."
  sm: '568px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  xxl: '1440px',
} as const
const bpKeys = R.keys(_breakpoints)
const breakpoints = Object.assign(R.values(_breakpoints), _breakpoints)

export type BP = keyof typeof _breakpoints

/**
 * Create a theme by merging the default theme with overrides.
 */
export const createTheme = ({
  branding,
}: PartialDeep<{branding: Branding}> = {}) => {
  // This seems to be used only by the tertiary buttons.
  const letterSpacings = {
    sm: '0.5px',
    md: '1px',
    lg: '2px',
  }

  // Spacing
  const space = {
    // The keys here are selectable widths, not breakpoints.
    xxs: toRem(6),
    xs: toRem(8),
    sm: toRem(10),
    md: toRem(12),
    lg: toRem(16),
    xl: toRem(24),
    xxl: toRem(32),
    xxxl: toRem(48),
    borderedBoxPaddingSm: '1.25rem',
    borderedBoxPaddingLg: '2.5rem',
    formIndent: toRem(16), // space.lg
  }

  // Grid gutters (gaps in CSS grid speak)
  // TODO - are we still using these?
  const gap = {
    // The keys here are breakpoints, not selectable widths. Ideally the
    // right-hand side should refer to the spacing scale, but I lifted these
    // as-is from the FlexGrid theme.
    xs: '1rem', // theme.space.lg
    sm: '1rem', // theme.space.lg
    md: '1.5rem', // theme.space.xl
    lg: '1.875rem', // theme.space.xxl almost
    xl: '1.875rem', // theme.space.xxl almost
    xxl: '2rem', // theme.space.xxxl ?
  } as const

  const colorScales = getColorScales({branding})
  const colors = getColors({colorScales})

  const borderWidths = {none: '0px', sm: '1px', md: '2px'}
  const borders = {
    fine: `${borderWidths.sm} solid ${colors.borderColor}`,
    std: `${borderWidths.md} solid ${colors.borderColor}`,
  }
  const radii = {none: '0px', sm: '4px', md: '6px', lg: '10px'}
  const shadows = {
    sm: '1px 2px 4px 0 rgba(0,0,0,0.05)',
    v2: '0 0 .9375rem 0.3125rem rgba(0,0,0,.1)',
    bottom:
      '0 1.3px 2.5px rgba(0, 0, 0, 0.075), 0 10px 20px rgba(0, 0, 0, 0.15)',
  }
  const underline = {
    textDecoration: `underline ${colors.textUnderline} solid ${borderWidths.sm}`,
  }
  const hovers = {
    raise: {
      transition: 'all 0.2s cubic-bezier(0, 0, 0.22, 1)',
      '&:hover': {
        cursor: 'pointer',
        boxShadow: 'bottom',
        transform: 'translateY(-0.5rem)',
        zIndex: 5,
      },
    },
  }

  const base = {
    borders,
    borderWidths,
    bpKeys,
    branding,
    breakpoints,
    colGap: gap,
    colorScales,
    colors,
    hovers,
    underline,
    gap,
    gaps, // separate from new constants
    letterSpacings,
    radii,
    rowGap: gap,
    shadows,
    space,
    toEm,
    toPx,
    transitions,
    toRem,
    ...getFonts({branding}),
    ...typography,
  } as const

  const theme = {
    ...base,
    // buttons depend on links for stealth styling
    links: getLinks(base),
  } as const

  return {
    ...theme,
    accordions: getAccordions(theme),
    buttons: getButtons(theme),
    checkboxes: getCheckboxes(theme),
    containers,
    dropdownMenus: getDropdownMenus(theme),
    fields: getFields(theme),
    flexGrids: getFlexGrids(theme),
    iconbuttons: getIconButtons(theme),
    images: getImages(theme),
    listDefinitions: getListDefinitions(theme),
    modals: getModals(theme),
    nav: getNav(theme),
    navLink: getNavLink(),
    summaries: getSummaries(theme),
    textContainers,
    tiles: getTiles(theme),
  } as const
}

export type StyledComponentsTheme = ReturnType<typeof createTheme>

// compat
export type {Rhythm, Variant} from 'quickstart/theme'
