import {TextContainer} from 'quickstart/components/layout/TextContainer'
import {useRenderedValue} from 'quickstart/components/tizra/MetaValue'
import {useMarkdown} from 'quickstart/hooks/useMarkdown'
import {ComponentProps, forwardRef} from 'react'

interface StaticContentProps {
  container?: ComponentProps<typeof TextContainer>['variant']
  staticType: string
  staticText: string
}

export const StaticContent = forwardRef<any, StaticContentProps>(
  ({container = 'default', staticType, staticText}, ref) => {
    const markdownHtml =
      useMarkdown(staticType === 'markdown' && staticText) || ''

    const displayType =
      staticType === 'markdown' || staticType === 'html' ? 'html' : 'text'
    const displayValue = staticType === 'markdown' ? markdownHtml : staticText

    const content = useRenderedValue({
      as: 'div',
      displayType,
      displayValue,
      variant: 'dangerous',
      reset: true,
      ref,
    })

    // TODO: configurable text container like markdown/abstract
    return <TextContainer variant={container}>{content}</TextContainer>
  },
)
