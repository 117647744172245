import {ComponentProps, forwardRef} from 'react'
import * as S from './styles'

const _Headline = forwardRef<any, ComponentProps<typeof S.Wrapper>>(
  (props, ref) => <S.Wrapper {...props} ref={ref} />,
)

export const Headline = Object.assign(_Headline, {
  Cover: S.Cover,
  Body: S.Body,
  Heading: S.Heading,
})
