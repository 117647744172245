import {FakeCover} from 'quickstart/components/content/FakeCover'
import {Image} from 'quickstart/components/content/Image'
import {
  useHack,
  useMergedRefs,
  useOEmbed,
  useResizeObserver,
} from 'quickstart/hooks'
import {
  ComponentProps,
  ReactNode,
  SyntheticEvent,
  forwardRef,
  useCallback,
  useState,
} from 'react'
import {MetaObject, TocEntry, isTocEntry, logger, meta} from 'tizra'
import * as S from './styles'

const log = logger('MetaThumb')

const n = (x: unknown): x is number => typeof x === 'number'

const nn = (x: unknown): x is string => typeof x === 'string' && !/\D/.test(x)

const px = <T,>(x: T | number) => (n(x) || nn(x) ? `${x}px` : x)

export type MetaThumbProps = Omit<ComponentProps<'div'>, 'position'> & {
  excerptPageImages?: boolean
  fallback?: boolean | ReactNode
  hoverable?: boolean
  metaObj?: MetaObject | TocEntry
  onLoad?: () => void
  bordered?: boolean
  shadowed?: boolean
  cover?: boolean
  position?: string
  height?: number
  ratio?: number
  maxHeight?: number
  minHeight?: number
  minRatio?: number
  maxRatio?: number
  width?: string
  loading?: 'eager' | 'lazy'
  onError?: () => void
  userAgent?: any
  coverImageProp?: string
}

export const MetaThumb = forwardRef<any, MetaThumbProps>(
  (
    {
      excerptPageImages,
      fallback,
      hoverable,
      metaObj,
      onLoad,
      bordered,
      shadowed,
      coverImageProp,

      // like objectFit and objectPosition, but cooperating with the flexbox wrapper
      cover = false,
      position = 'top center',

      // check for height/ratio set by caller
      height,
      ratio,

      // constraints on the flexbox wrapper
      maxHeight = height,
      minHeight = height,
      minRatio = ratio,
      maxRatio = ratio,

      // normally we fill wrapping container, unless otherwise specified
      width: boxWidth = '100%',

      // props that get passed directly to Image
      loading,
      onError,
      userAgent,

      ...props
    },
    forwardedRef,
  ) => {
    const fallbackHack = useHack('coverFallback')

    const avgRatio =
      minRatio && maxRatio ? (minRatio + maxRatio) / 2 : minRatio || maxRatio

    // Measure wrapper width.
    const [entry, widthRef] = useResizeObserver()
    const rectWidth = entry?.contentRect.width || 0

    // Check for oEmbed thumbnail.
    const [oEmbed, visRef] = useOEmbed({metaObj, loading})

    // We have two or three refs to satisfy, merge them into one.
    const ref = useMergedRefs([forwardedRef, visRef, widthRef])

    // Measure image aspect ratio when it loads.
    const [imageRatio, setImageRatio] = useState<null | number>(null)
    const handleLoad = useCallback(
      (e: SyntheticEvent<HTMLImageElement>) => {
        const {currentTarget: img} = e
        setImageRatio(img.naturalWidth / img.naturalHeight)
        return onLoad && onLoad(e)
      },
      [onLoad],
    )

    // Only set a height if not already set by caller.
    if (height === undefined) {
      // Prior to any measurements arriving, we can enforce minHeight.
      // This is mainly for a color swatch fake cover.
      if (n(minHeight)) {
        height = minHeight
        log.debug?.(`set cover height to ${height} (minHeight)`)
      }

      // Wrapper width arrives next, so we can enforce maxRatio. Similar to
      // minHeight above, this is mainly for a color swatch fake cover.
      if (rectWidth) {
        if (n(maxRatio) && rectWidth / (height || 0) > maxRatio) {
          height = rectWidth / maxRatio
          log.debug?.(`set cover height to ${height} (maxRatio ${maxRatio})`)
        }

        // When imageRatio arrives, calculate apparent wrapper height to enforce
        // maxHeight and minRatio. Note that rect.height measurement is not
        // reliable (usually 0, because it doesn't update when the content fills)
        if (n(imageRatio)) {
          height = Math.max(height || 0, rectWidth / imageRatio)

          // Enforce maxHeight and minRatio.
          if (n(maxHeight) && height > maxHeight) {
            log.debug?.(`set cover height to ${height} (maxHeight)`)
            height = maxHeight
          }
          if (n(minRatio) && rectWidth / height < minRatio) {
            height = rectWidth / minRatio
            log.debug?.(`set cover height to ${height} (minRatio ${minRatio})`)
          }
        }
      }
    }

    // Depending on the requested fit, image will need props for height, width,
    // objectFit and objectPosition. We simulate 'contain' with flexbox, instead
    // of using objectFit, so that the border surrounds the image rather than the
    // wrapper.
    const imageStyles: ComponentProps<typeof Image>['style'] =
      cover ?
        {
          objectFit: 'cover',
          objectPosition: position,
        }
      : {
          objectFit: 'contain',
        }

    const justifyContent =
      position.includes('top') ? 'flex-start'
      : position.includes('bottom') ? 'flex-end'
      : 'center'

    const alignItems =
      position.includes('left') ? 'flex-start'
      : position.includes('right') ? 'flex-end'
      : 'center'

    // Instruct the image how to fit in the box. The image needs to know which
    // direction it should apply 100% to fill, versus which way to apply auto to
    // maintain the aspect ratio.
    const wrapperRatio = rectWidth / (height ?? Infinity)
    if (cover) {
      // In cover mode, ensure the image fills the wrapper by pushing the lesser
      // dimension to 100%
      Object.assign(
        imageStyles,
        n(imageRatio) && imageRatio > wrapperRatio ?
          {height: '100%', width: '100%'}
        : {height: 'auto', width: '100%'},
      )
    } else {
      // In contain mode, ensure the image fits in the wrapper by constraining
      // the greater dimension to 100%
      Object.assign(
        imageStyles,
        n(imageRatio) && imageRatio < wrapperRatio ?
          {height: '100%', width: 'auto'}
        : {height: 'auto', width: '100%'},
      )
    }

    if (fallback === true) {
      fallback = metaObj && (
        <FakeCover
          alt={meta.name(metaObj)}
          hoverable={hoverable}
          bordered={bordered}
          shadowed={shadowed}
          tizraId={
            // determines color shading
            isTocEntry(metaObj) ? metaObj.bookTizraId : metaObj.tizraId
          }
        />
      )
    }

    // Wait to render until we know what's going on with oEmbed.
    const readyToRenderImage = !oEmbed.isPending

    return !metaObj ? null : (
        <S.Wrapper
          ref={ref}
          alignItems={alignItems}
          justifyContent={justifyContent}
          {...props}
          style={{
            height: px(height),
            width: px(boxWidth),
            ...(!readyToRenderImage &&
              avgRatio && {paddingBottom: `${100 / avgRatio}%`}),
            ...props.style,
          }}
        >
          {readyToRenderImage && (
            <Image
              alt={meta.name(metaObj)}
              src={meta.coverImage(metaObj, {
                excerptPageImages,
                fallbackHack,
                oEmbed: oEmbed.data,
                width: 900,
                coverImageProp,
              })}
              hoverable={hoverable}
              bordered={bordered}
              shadowed={shadowed}
              fallback={fallback}
              loading={loading}
              onError={onError}
              onLoad={handleLoad}
              userAgent={userAgent}
              style={{
                // When MetaThumb is inserted by way of TizraCoverElement aka
                // <tizra-cover> then the markdown styling applies a margin and
                // max-width to the image. This doesn't work correctly because
                // MetaThumb also has a wrapper div, and the contained image needs
                // to fill it completely. So we override that styling here.
                margin: 0,
                maxWidth: '100%',
                ...imageStyles,
              }}
            />
          )}
        </S.Wrapper>
      )
  },
)
