import {UseOEmbedReturn} from 'quickstart/hooks'
import {useLayoutEffect, useRef} from 'react'
import {logger} from 'tizra'

const log = logger('VideoPlayer')

interface VideoPlayerProps {
  oEmbed: UseOEmbedReturn
}

export const VideoPlayer = ({oEmbed}: VideoPlayerProps) => {
  const outerRef = useRef<HTMLDivElement>(null)
  const innerRef = useRef<HTMLDivElement>(null)

  // If the oEmbed provider doesn't supply their own responsiveness (hello
  // YouTube), then apply to wrapper here.
  useLayoutEffect(() => {
    const outer = outerRef.current
    const inner = innerRef.current
    if (!outer || !inner) return

    // Only attempt to adjust an iframe that is a top-level child of our
    // wrapper. If it's nested in divs, that indicates provider is handling
    // responsiveness.
    const iframe = [...inner.children].find(el => el.tagName === 'IFRAME') as
      | HTMLIFrameElement
      | undefined
    if (!iframe) return

    // Only attempt to adjust an iframe that has simple pixel width and height.
    const width = parseIframeDimension(iframe.getAttribute('width'))
    const height = parseIframeDimension(iframe.getAttribute('height'))
    if (!width || !height) return

    log.log('adjusting iframe from %sx%s to responsive', width, height)
    outer.style.aspectRatio = `${width} / ${height}`
    inner.style.position = 'absolute'
    inner.style.inset = '0'
    iframe.setAttribute('width', '100%')
    iframe.setAttribute('height', '100%')
  }, [])

  return (
    <div
      ref={outerRef}
      style={{width: '100%', height: 'auto', position: 'relative'}}
    >
      <div
        ref={innerRef}
        dangerouslySetInnerHTML={{__html: oEmbed.data?.html || ''}}
      />
    </div>
  )
}

/**
 * Parse an iframe width/height attribute, allowing bare numbers or px units,
 * but disallowing percentages (for example).
 */
function parseIframeDimension(x: unknown) {
  return typeof x === 'string' && /^\s*\d+(?:[.]\d*)?(?:px)?\s*$/.test(x) ?
      parseInt(x)
    : 0
}
