import {ComponentProps, forwardRef, ReactNode} from 'react'
import {logger} from 'tizra'
import * as S from './styles'

const log = logger('ListDefinition')

type ListDefinitionProps = Omit<ComponentProps<typeof S.List>, 'children'> & {
  pairs: Array<[ReactNode, ReactNode]>
  colons?: boolean
  itemProps?: ComponentProps<typeof S.Item>
  termProps?: ComponentProps<typeof S.Term>
  detailsProps?: ComponentProps<typeof S.Details>
}

export const ListDefinition = forwardRef<any, ListDefinitionProps>(
  (
    {pairs, colons = true, itemProps, termProps, detailsProps, ...props},
    ref,
  ) => {
    if (!pairs?.length) {
      return null
    }

    return (
      <S.List ref={ref} {...props}>
        {pairs.map(([term, details], i) => (
          <S.Item key={i} {...itemProps}>
            <S.Term {...termProps}>
              {typeof term === 'string' ? term.trim() : term}
              {colons && ':'}
            </S.Term>{' '}
            <S.Details {...detailsProps}>{details}</S.Details>
          </S.Item>
        ))}
      </S.List>
    )
  },
)
