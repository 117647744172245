import * as AK from '@ariakit/react'
import styled, {
  css,
  notSystemProp,
  shouldForwardProp,
  system,
  SystemProps,
  th,
} from 'quickstart/styled-components/system'

export const Accordion = styled.div<SystemProps>`
  ${th('accordions.wrapper')};
  ${system}
`

export const Icon = styled.div.withConfig({
  shouldForwardProp: (prop, ...rest) =>
    shouldForwardProp(prop, ...rest) && prop !== 'open',
})<{
  open?: boolean | undefined
}>(
  ({open}) => css`
    flex-shrink: 0;
    ${th('accordions.icon')};
    transform: ${open ? 'rotate3d(0, 0, 1, 180deg)' : 'rotate3d(0)'};
    transition: ${th('transitions.medium')};
  `,
)

export const Disclosure = styled(AK.Disclosure).withConfig({
  shouldForwardProp: notSystemProp,
})`
  ${th('accordions.title')};
  width: 100%;
  padding: ${th('accordions.padding')};
  background-color: transparent;
  border: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;

  &:focus,
  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: 0;

    ${Icon} {
      color: inherit;
    }
  }
`

export const DisclosureContent = styled(AK.DisclosureContent).withConfig({
  shouldForwardProp: notSystemProp,
})<{open?: boolean | undefined}>(
  ({open}) => css`
    padding: 0 ${th('accordions.padding')};
    ${open &&
    css`
      padding-bottom: ${th('accordions.padding')};
    `}
  `,
)

export const Group = styled.div<SystemProps>`
  ${th('accordions.wrapper')};
  ${system};

  ${Accordion} {
    border-top: none;
    border-left: none;
    border-right: none;
    &:last-child {
      border-bottom: none;
    }
  }
`
