import {Html} from 'quickstart/components/content/Html'
import {useHack, useMetaObjInfo} from 'quickstart/hooks'
import styled from 'quickstart/styled-components/system'
import {Falsish} from 'quickstart/types'
import {
  DisplayProps,
  MetaProps,
  getPropDisplays,
  partitionMetaDisplayProps,
} from 'quickstart/utils'
import {
  ComponentProps,
  ComponentPropsWithoutRef,
  ReactNode,
  forwardRef,
} from 'react'
import {logger, semiSplit, truthyArray} from 'tizra'

const LOG = logger('MetaValue')

type UseRenderedValueProps = Partial<
  Omit<ComponentPropsWithoutRef<typeof Html>, 'html'>
> &
  ComponentProps<'span'> & {
    displayType: 'html' | 'text' | 'string'
    displayValue: string
  }

// This will handle the as prop, and drop props that don't apply.
const Span = styled.span``

export const useRenderedValue = (
  {displayType, displayValue, ...props}: UseRenderedValueProps,
  ref?: any,
) => {
  switch (displayType) {
    case 'html':
      return <Html variant="raw" {...props} html={displayValue} ref={ref} />
    case 'text':
      return (
        <Html
          {...props}
          style={{whiteSpace: 'pre-line', ...props.style}}
          ref={ref}
        >
          {displayValue}
        </Html>
      )
    // @ts-expect-error intentional fall through
    default: {
      const unreachable: never = displayType
      LOG.error('unreachable displayType', unreachable)
      // falls through (for eslint)
    }
    case 'string':
      return (
        <Span ref={ref} {...props}>
          {displayValue}
        </Span>
      )
  }
}

type UseMetaValueProps = MetaProps &
  DisplayProps &
  Omit<UseRenderedValueProps, 'displayType' | 'displayValue'> & {
    prop?: string | Falsish | Array<string | Falsish>
  }

export const useMetaValue = (
  {prop: _props, ..._rest}: UseMetaValueProps,
  ref?: any,
) => {
  const log = LOG.logger(
    `${
      typeof _props === 'string' || Array.isArray(_props) ?
        JSON.stringify(_props)
      : _props
    }`,
  )
  const [metaProps, displayProps, rest] = partitionMetaDisplayProps(_rest)
  const {metaObj, metaType, tizraId, typeDefs} = useMetaObjInfo(metaProps)
  const br = useHack('br')
  const propDisplays = getPropDisplays(metaObj, typeDefs, {br, ...displayProps})
  const props = truthyArray(
    typeof _props === 'string' ? semiSplit(_props) : _props,
  )
  const namePropName = typeDefs?.[metaType!]?.namePropName

  // If prop is an array, then look for the first one with a non-empty value.
  let [prop, {count, displayType, displayValue, name}] = props
    .map(prop =>
      prop === '_name' ? [prop, namePropName || 'Title'] : [prop, prop],
    )
    .map(([prop, name]) => [prop, propDisplays[name]] as const)
    .find(([_, pd]) => pd?.count) || [
    null,
    {
      count: 0,
      displayType: 'string',
      displayValue: '',
      name: null,
    },
  ]

  // SubjectCollection has namePropName=Title in search-types, but the actual
  // prop seems to be Name, and it shows up properly on metaObj.name.
  if (
    metaObj &&
    typeDefs &&
    props.includes('_name') &&
    !count &&
    'name' in metaObj &&
    typeof metaObj.name === 'string'
  ) {
    const nameValue = metaObj.name.trim()
    if (nameValue) {
      log.debug?.(`${metaType} has bogus namePropName=${namePropName}`)
      displayValue = nameValue
      displayType = 'string'
      count = 1
      name = '_name'
    }
  }

  log.debug?.({
    count,
    displayType,
    displayValue,
    metaObj,
    name,
    prop,
    tizraId,
    propDisplays,
    displayProps,
  })

  // Call useTypedValue regardless of count, to avoid breaking rules of hooks.
  const result = useRenderedValue({...rest, displayType, displayValue}, ref)
  if (count) {
    return result
  }
}

type RenderFn = (content: ReactNode) => ReactNode

type MetaValueProps = Omit<UseMetaValueProps, 'children'> & {
  children?: RenderFn
  render?: RenderFn
}

export const MetaValue = forwardRef<any, MetaValueProps>(
  ({children, render = children, ...rest}, ref) => {
    const content = useMetaValue(rest, ref)
    return render ? render(content) : content
  },
)
