import {Icon} from 'quickstart/components/content'
import {transient} from 'quickstart/styled-components/system'
import {UnprefixTransientProps} from 'quickstart/types'
import {ComponentProps, forwardRef} from 'react'
import * as S from './styles'

type TagProps = UnprefixTransientProps<
  Omit<ComponentProps<typeof S.Tag>, 'color'>
>

const _Tag = forwardRef<any, TagProps>(
  ({children, color, inline, variant, ...props}, ref) => {
    return (
      <S.Tag
        ref={ref}
        role="listitem"
        {...transient({color, inline, variant})}
        {...props}
      >
        {children}
        {!!props.onClick && (
          <S.ActionIconWrapper>
            <Icon icon="close" size="100%" title="Remove" />
          </S.ActionIconWrapper>
        )}
      </S.Tag>
    )
  },
)

type TagGroupProps = ComponentProps<typeof S.TagGroup> & {title?: string}

const TagGroup = ({children, title, ...props}: TagGroupProps) => (
  <S.TagGroup>
    {title && <>{title} </>}
    {children}
  </S.TagGroup>
)

export const Tag = Object.assign(_Tag, {Group: TagGroup})
