import {Fragment, ReactNode} from 'react'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'
import * as Final from 'react-final-form-arrays'
import {ensureArray} from 'tizra'

type OneOrMany<T> = T | T[]

interface OrderableFieldArrayProps<FieldValue, T extends HTMLElement>
  extends Omit<
    Final.FieldArrayProps<FieldValue, T>,
    'children' | 'component' | 'render'
  > {
  component?: never
  render?: never
  orderable: true
  children:
    | ReactNode
    | OneOrMany<
        (props: Final.FieldArrayRenderProps<FieldValue, T>) => ReactNode
      >
}

interface NonOrderableFieldArrayProps<FieldValue, T extends HTMLElement>
  extends Final.FieldArrayProps<FieldValue, T> {
  orderable?: false
}

export type FieldArrayProps<FieldValue, T extends HTMLElement> =
  | OrderableFieldArrayProps<FieldValue, T>
  | NonOrderableFieldArrayProps<FieldValue, T>

export const FieldArray = <FieldValue, T extends HTMLElement = HTMLElement>({
  children,
  component: Component,
  name,
  orderable = false,
  render,
  droppableAs: DroppableType = 'div',
  draggableAs: DraggableType = 'div',
  ...props
}: FieldArrayProps<FieldValue, T>) =>
  !orderable ?
    <Final.FieldArray
      name={name}
      children={children as ReactNode}
      component={Component}
      render={render}
      {...props}
    />
  : <Final.FieldArray name={name} {...props}>
      {renderProps => {
        const {fields} = renderProps
        return (
          <DragDropContext
            onDragEnd={({destination, source}: any) => {
              if (destination) {
                fields.move(source.index, destination.index)
              }
            }}
          >
            {ensureArray(children).map((unresolvedChild, index) => {
              let resolvedChild: ReactNode
              if (typeof unresolvedChild === 'function') {
                const renderedChild = unresolvedChild(renderProps)
                resolvedChild =
                  Array.isArray(renderedChild) ?
                    <Droppable
                      droppableId={`droppable-${fields.name}-${index}`}
                    >
                      {({innerRef, droppableProps, placeholder}) => (
                        <DroppableType ref={innerRef} {...droppableProps}>
                          {renderedChild.map((item, i) => (
                            // react-beautiful-dnd explicitly says to avoid
                            // using the index as the draggableId, see
                            // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/identifiers.md
                            // But if we use a stable id attached to the object,
                            // then we get render flashing before it stabilizes.
                            // Our assumption here is that the main reason to
                            // avoid indexed ids is for adding-while-dragging and
                            // that sort of thing, so we'll pragmatically use what
                            // works for now.
                            <Draggable key={i} draggableId={`${i}`} index={i}>
                              {({
                                innerRef,
                                dragHandleProps,
                                draggableProps,
                              }) => (
                                <DraggableType
                                  ref={innerRef}
                                  {...dragHandleProps}
                                  {...draggableProps}
                                >
                                  {item}
                                </DraggableType>
                              )}
                            </Draggable>
                          ))}
                          {placeholder}
                        </DroppableType>
                      )}
                    </Droppable>
                  : renderedChild
              } else {
                resolvedChild = unresolvedChild
              }
              return <Fragment key={index}>{resolvedChild}</Fragment>
            })}
          </DragDropContext>
        )
      }}
    </Final.FieldArray>
