import styled, {css, system, th} from 'quickstart/styled-components/system'

export const Label = styled.label<{required?: boolean}>(
  ({required}) => css`
    display: flex;
    flex-shrink: 0;
    align-items: flex-start;
    ${th('fields.label')};
    ${system};
    user-select: none;

    > * {
      &:not(:last-child) {
        margin-right: ${th('space.xxs')};
      }

      :last-child {
        ${required &&
        css`
          &::after {
            content: '*';
            color: ${th('colors.textRequired')};
            margin-left: ${th('space.xxs')};
          }
        `};
      }
    }
  `,
)

// This goes before the label content in the markup, so that the last-child
// stuff above works as expected. Then we use flex order to put it at the end.
export const RightLabel = styled.div`
  flex: 1 1 100%;
  order: 1;
  text-align: right;
`

// TODO welcome-ui applies a text color according to variant
// TODO margin-left var. should reference input spacing
export const Hint = styled.div<{variant?: 'default'}>(
  ({variant}) => css`
    display: flex;
    margin-left: ${th('space.formIndent')};
    margin-top: ${th('space.sm')};
    align-items: center;
    ${th(`fields.hint.base`)};
    ${th(`fields.hint.${variant || 'default'}`)};
    ${system};
  `,
)

const rowStyles = css`
  margin-right: ${th('space.sm')};
`

const columnStyles = css`
  margin-bottom: ${th('space.sm')};
  margin-left: ${th('space.formIndent')};
`

const checkableFieldStyles = css`
  ${th('fields.checkablelabel.default')};
  margin-bottom: ${th('space.sm')};
`

export const FieldGroup = styled.fieldset`
  width: 100%;
  min-width: 0;
  min-height: 0;
  margin: 0;
  padding: 0;
  ${th('fields.fieldset')};
  ${system};

  & > ${Label} {
    margin-bottom: ${th('space.sm')};
  }
`

export const Field = styled.div<{
  flexDirection: 'row' | 'column'
  checkableField?: boolean
  checked?: boolean
}>(
  props => css`
    ${FieldGroup} {
      margin-bottom: ${props.checkableField && th('space.xxs')};
    }
    ${Label} {
      ${props.flexDirection === 'row' && rowStyles};
      ${props.flexDirection === 'column' && columnStyles};
      ${props.checkableField && checkableFieldStyles};
      ${props.checked && th('fields.checkablelabel.checked')}
    }
    ${system};
  `,
)

export const Input = styled.div`
  flex-shrink: 0;
`
