import {Children, forwardRef, ReactNode} from 'react'
import * as S from './styles'

type GridProps = React.ComponentProps<typeof S.Grid> & {
  children?: ReactNode
  cols?: any
  inset?: any
  reversed?: boolean
  theme?: any
}

export const Grid = forwardRef<any, GridProps>(
  (
    {
      children,
      reversed = false,
      theme, // XXX work around Linaria passing incompatible theme
      ...props
    },
    ref,
  ) => (
    <S.Grid ref={ref} {...props}>
      {reversed ? Children.toArray(children).reverse() : children}
    </S.Grid>
  ),
)
