import {useRef} from 'react'

/**
 * React hook to preserve defined value.
 */
export const useSticky = <T>(x: T | undefined): T | undefined => {
  const xRef = useRef<T | undefined>()
  if (x !== undefined) {
    xRef.current = x
  }
  return xRef.current
}
