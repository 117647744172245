import {dataProps} from 'quickstart/theme'
import {UnprefixDataProps} from 'quickstart/types'
import {ComponentProps, forwardRef, Fragment, ReactNode} from 'react'
import {Container} from '../Container'
import * as S from './styles'

type SectionProps = Partial<
  UnprefixDataProps<ComponentProps<typeof S.Section>>
> & {
  bleed?: boolean
  heading?: ReactNode
}

const _Section = forwardRef<any, SectionProps>(
  ({bleed = false, children, heading, variant = 'default', ...props}, ref) => {
    if (heading && typeof heading === 'string') {
      heading = <S.SectionHeading>{heading}</S.SectionHeading>
    }

    const MaybeContainer = bleed ? Fragment : Container

    return (
      <S.Section {...dataProps({variant})} {...props} ref={ref}>
        <MaybeContainer>
          {heading}
          {children}
        </MaybeContainer>
      </S.Section>
    )
  },
)

export const Section = Object.assign(_Section, {Heading: S.SectionHeading})
