import {identity} from 'rambdax'
import {ComponentProps, forwardRef} from 'react'
import * as Final from 'react-final-form'
import {SetRequired} from 'type-fest'
import {Field} from '../Field'

interface ConnectedFieldProps
  extends SetRequired<ComponentProps<typeof Field>, 'name'> {
  [x: string | number | symbol]: unknown
}

export const ConnectedField = forwardRef<any, ConnectedFieldProps>(
  ({children, component, name, type, ...rest}, ref) => (
    <Final.Field name={name} type={type} parse={identity}>
      {({input, meta}) => (
        <Field
          ref={ref}
          {...input}
          {...meta}
          error={meta.error || meta.submitError}
          {...rest}
          component={component}
          connected
        >
          {children}
        </Field>
      )}
    </Final.Field>
  ),
)
